
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyATxSYkN80EsRbPmGMKKKuzo169ZXvQI54",
  authDomain: "kryptonig.firebaseapp.com",
  databaseURL: "https://kryptonig-default-rtdb.firebaseio.com",
  projectId: "kryptonig",
  storageBucket: "kryptonig.appspot.com",
  messagingSenderId: "789240259240",
  appId: "1:789240259240:web:745ccd8e6f44955c86189d",
  measurementId: "G-VHJXZP1LW0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage, analytics };
