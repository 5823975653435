import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import  { useState } from "react";
import { FormInput, SelectInput } from "./inputs/inputs";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
export interface Props {
  isOpen: any;
  onOpen: any;
  onClose: any;
}

export const NewUploadPopup: React.FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [milestones, setMilestones] = useState("");
  const [industry, setIndustry] = useState("");
  const [image, setImage] = useState();
  const [picture, setPicture] = useState(null);

  // @ts-expect-error
  const onImageChange = (event) => {
    if (event.target.files && event.target.files) {
      // @ts-expect-error
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    setPicture(event.target.files[0]);
  };

  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Box m="auto" w="50%" textAlign="center" bg="">
          <>
            <label className="kyc">
              <Center m="20px" color="#B1AEAE" _hover={{ color: "black" }}>
                <Avatar size="lg" name="Dan Abrahmov" src={image} />
              </Center>

              <input type="file" name="picture" onChange={onImageChange} />
            </label>
          </>{" "}
          <Heading color="#5F6D7E" size="sm">
            Click to upload image of project
          </Heading>
          <FormInput
            isRequired={true}
            label="Project name"
            placeholder="Enter your product name"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <FormInput
            isRequired={true}
            label="Milestones"
            placeholder="Enter your Milestones name"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <SelectInput
            isRequired={true}
            label="Industry"
            value={industry}
            onChange={(e) => setMilestones(e.target.value)}
          />
          <Flex justifyContent="space-between" mt="30px" mb="50px">
            <Button h="35px" variant="secondary2" onClick={onClose}>
              Cancel
            </Button>
            <Button h="35px" variant="primary">
              Create Project
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};
