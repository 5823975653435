import {
    Button,
    Flex,
    Heading,
    Image,
    Text,
  } from "@chakra-ui/react";
  import { useState, useEffect } from "react";
  import KycComponent from "../../components/kycComponent";
  import { useNavigate } from "react-router-dom";
  import successImage from "../../images/success.png";
  import "react-phone-number-input/style.css";
  import { onAuthStateChanged } from "@firebase/auth";
  import { auth } from "../../firebase";
  
  export const KycSuccess = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<any>(null);
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        } else {
          navigate("/");
        }
      });
  
      return () => unsubscribe(); // Cleanup subscription on unmount
    }, [navigate]);
  
    if (!user || !user.emailVerified) {
      navigate("/");
      return null; // Return null or another component while user is not verified
    }

    const handleButtonClick = () => {
        navigate("/home");
    };
  
    return (
      <KycComponent index={3}>
        <Flex justifyContent="center" mt="100px">
          <Image src={successImage} />
        </Flex>
  
        <Heading size="md">KYC Successful</Heading>
        <Text fontSize="14px" color="gray.600" mb="25px" mt="25px">
        <strong>Hey {user.displayName}</strong>, congratulations on completing your KYC. You're all set! Expect amazing products from us shortly.
        </Text>


        <Button w="100%" variant="primary" onClick={handleButtonClick} >
            Go to Dashboard
        </Button>
  
      </KycComponent>
    );
  };
  
  export default KycSuccess;
  