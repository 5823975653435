import {
  Input,
  FormControl,
  FormErrorMessage,
  Select,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FormInputProps, SelectInputProps } from "./type";

export const FormInput: React.FC<FormInputProps> = ({
  onChange,
  label,
  type,
  mr,
  onInput,
  id,
  isInvalid,
  error,
  value,
  placeholder,
  min,
  max,
  isRequired,
  size,
  autocomplete,
  onBlur,
  onClick,
  innerElement,
  onPaste,
}) => {
  return (
    <FormControl textAlign="left" isInvalid={isInvalid} mr={mr} mb="30px">
      {label && (
        <span>
          {label}
          <br></br>
        </span>
      )}

      <InputGroup>
        {innerElement ? (
          <InputLeftElement pointerEvents="none">
            {innerElement}
          </InputLeftElement>
        ) : (
          ""
        )}

        <Input
          autoComplete={autocomplete}
          onPaste={onPaste}
          variant="filled"
          onInput={onInput}
          blur={onBlur}
          onClick={onClick}
          min={min}
          h="50px"
          max={max}
          size={size}
          {...error}
          isRequired={isRequired}
          type={type}
          border="1px solid #1B1D2847"
          w="100%"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </InputGroup>

      <FormErrorMessage>{isInvalid}</FormErrorMessage>
    </FormControl>
  );
};

export const SelectInput: React.FC<SelectInputProps> = ({
  label,
  isInvalid,
  error,
  isRequired,
  options,
  mr,
  onChange,
  placeholder,
  value,
  isDisabled,
  bg,
  w,
  color,
  size,
}) => {
  return (
    <FormControl textAlign="left" mb="30px" mr={mr}>
      <span>{label}</span>
      <br></br>
      <Select
        variant="filled"
        {...error}
        size={size}
        value={value}
        isDisabled={isDisabled}
        isRequired={isRequired}
        onChange={onChange}
        w={w}
        bg={bg}
        color={color}
      >
        <option value="">{placeholder}</option>
        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.name}
          </option>
        ))}
      </Select>
      <p style={{ color: "#FC8181", fontSize: "14px" }}>{isInvalid}</p>
    </FormControl>
  );
};
