import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import { LuGift } from "react-icons/lu";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuBadgeInfo } from "react-icons/lu";
import { IoIosStar } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const Rewards = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const datas = {
      dob: dob,
      firstname: firstname,
      gender: gender,
      country: country,
      lastname: lastname,
    };
    localStorage.setItem("kycData1", JSON.stringify(datas));
    navigate("/kyc2");
  };
  const reward = [
    {
      name: "Create an account",
      icon: <IoPersonSharp size="30px" />,
      point: "250",
    },
    {
      name: "Join or create your first forum",
      icon: <LuBadgeInfo size="30px" />,
      point: "",
    },
    { name: "VIP collections", icon: <IoIosStar size="30px" />, point: "" },
  ];
  return (
    <>
      <Heading size="sm">Rewards</Heading>
      <Heading
        bg="white"
        textAlign="center"
        color="#777777"
        size="md"
        px="20px"
      >
        Earn points
      </Heading>

      <Flex justifyContent="space-between" flexWrap="wrap" bg="#E3E3E3" p="5px">
        {reward.map((item) => (
          <Box
            w={["100%", "100%", "49.4%", "49.4%", "24%", "24%"]}
            bg="white"
            p="20px"
            minH="200px"
            mb="20px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text color="#FF6934"> {item?.icon}</Text>
            <Heading size="sm">{item?.name}</Heading>
            <Text color="#777777">{item?.point} points</Text>
          </Box>
        ))}
      </Flex>
    </>
  );
};
//color="#D7D7D7"
export default Rewards;
