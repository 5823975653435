import React from "react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";

import {
  Box,
  Button,
  Spacer,
  Flex,
  IconButton,
  Heading,
  useDisclosure,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Center,
  Avatar,
  Image,
} from "@chakra-ui/react";

import phone from "../../images/logo.png";
import { HamburgerIcon } from "@chakra-ui/icons";
import { IoHelpCircle, IoPersonOutline } from "react-icons/io5";
import { BiHomeCircle } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { FaPeopleGroup } from "react-icons/fa6";
import { PiMedalMilitary } from "react-icons/pi";
import { CiCircleQuestion } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { BsShieldLock } from "react-icons/bs";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
export const DashboardLayout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //"#b4816b"

  // const fullname = JSON.parse(localStorage.getItem("fullname"));

  const navigate = useNavigate();
  const menuList = [
    {
      name: "Home",
      link: "/",
      icon: <BiHomeCircle size="25px" />,
    },
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: <RxDashboard size="25px" />,
    },
    {
      name: "Community",
      link: "/community",
      icon: <FaPeopleGroup size="25px" />,
    },
    {
      name: "Rewards",
      link: "/rewards",
      icon: <PiMedalMilitary size="25px" />,
    },
    {
      name: "Profile",
      link: "/profile",
      icon: <IoPersonOutline size="25px" />,
    },
    {
      name: "FAQs & Help",
      link: "/faqs",
      icon: <CiCircleQuestion size="25px" />,
    },
    {
      name: "Settings",
      link: "/settings",
      icon: <IoSettingsOutline size="25px" />,
    },
  ];

  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      user.getIdToken().then((idToken) => {
        console.log("user token is: ", idToken);
      }).catch((error) => {
        console.error("Error getting ID token: ", error);
      });
    }
  }, [user]); 

  


  return (
    <Box>
      <Box display="flex">
        <Box
          w={["20%", "20%", "30%", "30%", "20%", "20%"]}
          boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          display={["none", "none", "block", "block"]}
        >
          <Flex
            ml="-30px"
            mb="20px"
            pb="18px"
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          >
            <Image h="50px" src={phone} />
            <Heading mt="22px" ml="-35px" size="sm">
              <a href="/">Kryptonig</a>
            </Heading>
          </Flex>

          {menuList.map((item) => (
            <Text
              cursor="pointer"
              p="7px"
              ml="15px"
              key={item?.link}
              bg="transparent"
              w="100%"
              mb="10px"
              borderRadius={0}
              onClick={() => navigate(`${item?.link}`)}
              color={
                window.location.pathname.includes(`${item?.link}`)
                  ? "#FE8F66"
                  : "#54667F"
              }
              display="flex"
              _hover={{
                color: "#FE8F66",
              }}
            >
              {item?.icon}
              <Text ml="10px" mt="2px">
                {item?.name}
              </Text>
              {window.location.pathname.includes(`${item?.link}`) && (
                <Text ml="5px" mt="7px">
                  <GoDotFill size="16px" />
                </Text>
              )}
            </Text>
          ))}

          <Box
            m="20px"
            p="15px"
            bg="primary"
            textAlign="center"
            borderRadius="10px"
            color="white"
            mt="40px"
          >
            <Center>
              <Box p="20px" bg="white" borderRadius="50%" w="80px">
                <BsShieldLock color="#ED7662" size="35px" />
              </Box>
            </Center>

            <Heading mt="20px" size="sm">
              Keep you safe!
            </Heading>
            <Text mt="10px" fontSize="xs">
              Update your security, keep your account safe!
            </Text>
            <Heading mt="20px" size="sm">
              Complete KYC
            </Heading>
          </Box>
        </Box>
        <Box w="100%" h="100vh" overflowY="scroll">
          <Box
            position="sticky"
            top="0"
            bg="white"
            zIndex="1000"
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
          >
            <Box p="10px">
              <Flex>
                <Spacer />
                <Box
                  justifyContent="space-between"
                  display={["none", "none", "flex", "flex"]}
                >
                  <Flex p="8px" px="20px" borderLeft="1px solid #DFE2E6">
                    <IoNotifications size="28px" color="#627490" />
                    <Box
                      borderRadius="50%"
                      fontSize="12px"
                      bg="#D12564"
                      color="white"
                      px="5px"
                      h="17px"
                      ml="-13px"
                    >
                      2
                    </Box>
                  </Flex>
                  <Flex p="8px" px="20px" borderLeft="1px solid #DFE2E6">
                    <IoHelpCircle size="28px" color="#627490" />
                    <Text ml="5px"> Help</Text>
                  </Flex>
                  <Flex p="8px" borderLeft="1px solid #DFE2E6">
                    <Avatar size="sm" src="https://bit.ly/broken-link" />
                    <Text ml="10px" mt="4px">
                      {user?.displayName}
                    </Text>
                  </Flex>
                </Box>
                <Box display={["flex", "flex", "none", "none"]} mt="10px">
                  <Button
                    as={IconButton}
                    onClick={onOpen}
                    aria-label="Options"
                    icon={<HamburgerIcon />}
                    variant="primary"
                  />
                  <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    size="xs"
                  >
                    <DrawerOverlay />
                    <DrawerContent textAlign="left">
                      <DrawerCloseButton />
                      <Box mt="70px">
                        {menuList?.map((data) => (
                          <Box key={data?.name}>
                            <Button
                              variant="transpare"
                              onClick={() => {
                                navigate(data?.link);
                                onClose();
                              }}
                            >
                              {data?.name}
                            </Button>
                            <br></br>
                          </Box>
                        ))}
                      </Box>
                    </DrawerContent>
                  </Drawer>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box p="30px">
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
