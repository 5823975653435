import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { MdOutlineDeleteForever, MdOutlineInfo } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../firebase";
import { onAuthStateChanged } from "@firebase/auth";

export interface Props {
  isOpen: any;
  onOpen: any;
  onClose: any;
}

export const UploadPopup: React.FC<Props> = ({ isOpen, onOpen, onClose }) => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);
  const [picture, setPicture] = useState<File | null>(null);
  const [image2, setImage2] = useState<string | null>(null);
  const [picture2, setPicture2] = useState<File | null>(null);
  const [show, setShow2] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setPicture(file);
    }
  };

  const onImageChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImage2(URL.createObjectURL(file));
      setPicture2(file);
    }
  };

  const onSubmitPic = async () => {
    if (picture) {
      setShow2(true);
    }

    if (picture && picture2) {
      setLoading(true); // Start loading
      try {
        const frontRef = ref(storage, `kyc/${user.uid}/frontPicture.jpg`);
        await uploadBytes(frontRef, picture);

        const backRef = ref(storage, `kyc/${user.uid}/backPicture.jpg`);
        await uploadBytes(backRef, picture2);

        const [frontURL, backURL] = await Promise.all([
          getDownloadURL(frontRef),
          getDownloadURL(backRef),
        ]);

        const userDocRef = doc(db, "user_kyc", user.uid);
        await updateDoc(userDocRef, {
          frontPictureURL: frontURL,
          backPictureURL: backURL,
          status: "complete",
        });

        navigate("/kyc-success");
      } catch (error) {
        console.error("Error uploading pictures or updating document: ", error);
        // Handle error state or retry logic here
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      // Handle case where either picture or picture2 is not selected
      console.error("Please upload both front and back pictures.");
    }
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box
          borderRadius="10px"
          m="10px"
          bg="#FFF0EF"
          p="10px"
          fontSize="12px"
          w="40%"
        >
          <Flex>
            <MdOutlineInfo size="40px" />
            <Text ml="10px">
              <b>ID – {show ? "Back" : "Front"} Side </b>
              <br />
              All four corners should be visible. Avoid the red colored zone.
            </Text>
          </Flex>
        </Box>
        <ModalCloseButton />
        <Box m="auto" w="50%" textAlign="center">
          {show ? (
            <>
              {image2 ? (
                <Box>
                  <Flex justifyContent="center" h="inherit">
                    <Image
                      alt="preview"
                      objectFit="cover"
                      src={image2}
                      mr="10px"
                      maxH="300px"
                    />
                  </Flex>
                  <Button
                    mt="-66px"
                    size="sm"
                    bg="transparent"
                    color="red"
                    _hover={{ background: "none" }}
                    onClick={() => {
                      setImage2(null);
                      setPicture2(null);
                    }}
                  >
                    <MdOutlineDeleteForever size="30px" />
                  </Button>
                </Box>
              ) : (
                <label className="kyc">
                  <Center color="#B1AEAE" _hover={{ color: "black" }}>
                    <IoCloudUploadOutline size="200px" />
                  </Center>
                  <input
                    type="file"
                    name="picture2"
                    accept="image/*"
                    onChange={onImageChange2}
                  />
                </label>
              )}
            </>
          ) : (
            <>
              {image ? (
                <Box>
                  <Flex justifyContent="center" h="inherit">
                    <Image
                      alt="preview"
                      objectFit="cover"
                      src={image}
                      mr="10px"
                      maxH="300px"
                    />
                  </Flex>
                  <Button
                    mt="-66px"
                    size="sm"
                    bg="transparent"
                    color="red"
                    _hover={{ background: "none" }}
                    onClick={() => {
                      setImage(null);
                      setPicture(null);
                    }}
                  >
                    <MdOutlineDeleteForever size="30px" />
                  </Button>
                </Box>
              ) : (
                <label className="kyc">
                  <Center color="#B1AEAE" _hover={{ color: "black" }}>
                    <IoCloudUploadOutline size="200px" />
                  </Center>
                  <input
                    type="file"
                    name="picture"
                    accept="image/*"
                    onChange={onImageChange}
                  />
                </label>
              )}
            </>
          )}
          Upload the {show ? "Back" : "Front"} side of your national identity card{" "}
          <Button
            w="100%"
            mt="30px"
            mb="50px"
            variant="primary"
            onClick={onSubmitPic}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" /> : "Next"}
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};
