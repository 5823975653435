import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Center,
  Link,
} from "@chakra-ui/react";

import { FaTelegram, FaLinkedinIn, FaFacebook, FaTwitter, FaFacebookF } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { ReactNode } from "react";
import { ChevronUpIcon } from "@chakra-ui/icons";

export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const KycComponent: React.FC<FormInputProps> = ({ children, index }) => {
  const quickLinks = ["About", "Show Room ", "RoadMap", "FAQs"];
  const communityLinks = ["How it works", "Blockchain", "Get in Touch"];
  return (
    <Box
      borderTop="1px solid #83838340"
      pt={["80px"]}
      m="auto"
      my="30px"
      px={["32px","32px","100px"]}
    >
      <Flex justifyContent="space-between" flexWrap="wrap" flexDirection={["column", "column", "row"]}>
        <Box w={["90%", "90%","90%","50%"]}>
          <Heading mt="10px" fontSize="19px" fontWeight="900" display={"flex"}>
            <Box
              bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
            >
              KryptoNig
            </Box>
          </Heading>
          <Text
            w={["90%", "90%", "90%", "90%", "45%", "45%"]}
            my="10px"
            color="#7B7583"
          >
            We are a project, just as yours. Fund and contribute to our
            community now to earn rewards.
          </Text>
          <Box
            display={["none", "none", "none", "flex", "flex", "flex"]}
            justifyContent="space-between"
            mt="20px"
            w="10%"
            gap={4}
          >
            <Link href="https://www.facebook.com/profile.php?id=61563282131563&mibextid=ZbWKwL" target="_blank">
          <FaFacebookF size="25px" />
        </Link>
        <Link href="https://x.com/KryptonigXYZ?t=bewS4WuMMm0ZWTMpvSJlzA&s=09" target="_blank">
          <FaTwitter size="25px" />
        </Link>
        <Link href="https://www.instagram.com/kryptonigxyz?igsh=MTRwbDFpMHdlaGNtbQ==" target="_blank">
          <FaInstagram size="25px" />
        </Link>
        <Link href="https://www.linkedin.com/posts/kryptonig-llc_kryptonig-llc-linkedin-activity-7265342685979279360-xMQ_?utm_source=share&utm_medium=member_android" target="_blank">
          <FaLinkedinIn size="25px" />
        </Link>
        <Link href="https://t.me/kryptonigXYZ" target="_blank">
          <FaTelegram size="25px" />
        </Link>
          </Box>
        </Box>
        <Flex mt="20px"
        
        flexDirection={["column", "row", "row"]} w={["100%","100%","100%", "35%"]}>
          <Box w={"50%"}>
            <Heading size="md" mb="10px" ml="10px">
              Quick Link
            </Heading>
            {quickLinks.map((data) => (
              <>
                <Button
                  key={data}
                  fontSize="14px"
                  variant="transpare"
                  pr="70px"
                >
                  {data}
                </Button>
                <br></br>
              </>
            ))}
          </Box>
          <Box w={"50%"}>
            <Heading size="md" mb="10px" ml="10px">
              Community
            </Heading>
            {communityLinks.map((data) => (
              <>
                <Button
                  key={data}
                  fontSize="14px"
                  variant="transpare"
                  pr="70px"
                >
                  {data}
                </Button>
                <br></br>
              </>
            ))}
          </Box>
        </Flex>
      </Flex>
      <Flex
        // flexWrap="wrap"
        justifyContent={[
          "center",
          "center",
          "space-between",
          "space-between",
          "space-between",
        ]}
        color="#7B7583"
        mt="30px"
        flexDirection={["column", "row", "row"]}
      >
        <Text textAlign="center" mt="20px" fontSize="sm">
          Copyright © 2024 Kryptonig All Rights Reserved.
        </Text>
        <Flex mt="10px" gap={0}  justifyContent={"right"}>
          <Button fontSize="12px" variant="transpare" pr="70px">
            Privacy policy
          </Button>
          <Button fontSize="12px" variant="transpare" pr="70px">
            Terms of Use
          </Button>

          <Button fontSize="12px" variant="transpare" pr="70px">
            <ChevronUpIcon />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default KycComponent;
