import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Center,
  Image,
  Link,
} from "@chakra-ui/react";

import { FaHeart, FaRegComment, FaRegHeart, FaTwitter } from "react-icons/fa6";

import { BsSend } from "react-icons/bs";

export interface BlogPostProps {
  item: any;
}
export const BlogPostCardComponent: React.FC<BlogPostProps> = ({ item }) => {
  return (
    <Flex
      gap={[5, 5, 20]}
      py="60px"
      px="80px"
      borderTop="1px solid #26262633"
      flexDirection={["column", "column", "row"]}
    >
      <Box flexGrow={1} w={["95%", "95%", "20%", "10%"]}>
        <Flex flexDirection={["row", "column", "column", "column", "row"]}>
          <Box>
            <Image src={item.author_pic} mr="10px" 
             borderRadius='full'
             boxSize='60px'
             alt={item.author}
            />
          </Box>
          <Box mt="10px">
            <Heading fontSize="18px" fontWeight={700} color="#0F051D">
              {item.author}
            </Heading>
            <Text color="#98989A">{item.author.desc}</Text>
          </Box>
        </Flex>
      </Box>
      <Box flexGrow={3} justifyContent="left" w={["98%", "98%", "60%", "50%"]}>
        <Box>
          <Text fontSize="18px" color="#98989A">
            {item.published}
          </Text>
          <Link
            href={`blog/posts/${item.title.toLowerCase().replaceAll(" ", "-")}-${item.post_id}`}
          >
            <Heading fontSize="22px" mt="24px">
              {item.title}
            </Heading>
          </Link>
          <Text color="#98989A">{item.description}</Text>
        </Box>
        <Flex gap={2} mt="10px">
          <Box>
            <Flex
              background="#1A1A1A"
              color="#98989A"
              px="16px"
              py="6px"
              borderRadius="100px"
              h={"35px"}
              >
              <Box mt="2px">
                {item.isLiked ? <FaHeart color="red" /> : <FaRegHeart />}
              </Box>
              <Text ml="2px" fontSize="14px" p={0}>
                {item.likes}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex
              background="#1A1A1A"
              color="#98989A"
              px="16px"
              py="6px"
              borderRadius="100px"
              h={"35px"}
              >
              <Box mt="2px">
                <FaRegComment />
              </Box>
              <Text ml="2px" fontSize="14px" p={0}>
                {item.commentCount??'0'}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Flex
              background="#1A1A1A"
              color="#98989A"
              px="16px"
              py="6px"
              borderRadius="100px"
              h={"35px"}
              >
              <Box mt="2px">
                <BsSend />
              </Box>
              <Text ml="2px" fontSize="14px" p={0}>
                {item.shares}
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
      <Center>
        {" "}
        <Link flexGrow={1} href={`blog/posts/${item.title.toLowerCase().replaceAll(" ", "-")}-${item.post_id}`} w="20%">
          <Button ml="10px" px="20px" variant="primary2" borderRadius="50px">
            View Blog
          </Button>
        </Link>
      </Center>
    </Flex>
  );
};

export default BlogPostCardComponent;
