import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  Link,
  Grid,
  Container,
  Divider,
  VStack,
  HStack,
  UnorderedList,
  ListItem,
  AbsoluteCenter,
  Spinner,
} from "@chakra-ui/react";
import {
  FaCommentAlt,
  FaHeart,
  FaPlus,
  FaRegComment,
  FaRegHeart,
} from "react-icons/fa";
import {
  BsArrowUpRight,
  BsArrowUpRightCircleFill,
  BsSend,
} from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import Footer from "../components/footer";
import Header from "../components/header";
import { useLocation } from "react-router-dom";

import newsH from "../images/news.png";
import news1 from "../images/news1.png";
import news2 from "../images/news2.png";
import news3 from "../images/news3.png";
import john from "../images/John Techson.png";

import { SlEye } from "react-icons/sl";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import he from "he";
export const BlogSingle = () => {
  const location = useLocation();
  const url = location.pathname;
  const [contentHeight, setContentHeight] = useState("500px");
  const [post, setPost] = useState<{
    id: string;
    user: string;
    email: string;
    author: string;
    author_pic: string;
    post_id: string;
    title: string;
    post: string;
    background_img: string;
    toc: string;
    category: string;
    read_time: string;
    likes: string;
    views: string;
    shares: string;
    published: string;
    status: string;
    up_time: string;
    admin: string;
    remark: string;
    isLiked: boolean;
  } | null>(null);
  const [isLoaded, setIsLoaded] = useState(false); // Added to track loading state
  const [showPostCollapseButton, setShowPostCollapseButton] = useState(true);
  useEffect(() => {
    const fetchBlogPost = async () => {
      const slug = url.split("/").pop();
      const postId = slug?.split("-").pop();
      const response = await fetch(`/api/blog_posts.php?post_id=${postId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data, "data", slug, postId);
      setPost(data);
      setIsLoaded(true); // Set isLoaded to true after data is fetched
    };

    fetchBlogPost();
  }, [url]);

  const similarPost = [
    {
      title: "A Decisive Victory for Progressive Policies",
      category: "Politics",
      likes: "2.2k",
      shares: "60",
      image: news1,
    },
    {
      title: "Tech Giants Unveil Cutting-Edge AI Innovations",
      category: "Technology",
      likes: "6k",
      shares: "92",
      image: news2,
    },
    {
      title: "COVID-19 Variants",
      category: "Health",
      likes: "10k",
      shares: "124",
      image: news3,
    },
  ];

  return (
    <Box>
      {/* <Header /> */}

      {isLoaded && post ? (
        <Box>
          <Box
            backgroundImage={`url('${post.background_img}')`}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={["200px", "200px", "500px"]}
            position={"relative"}
          >
            <Box
              position={"absolute"}
              width={"100%"}
              height={"100%"}
              zIndex={0}
              background={"rgba(0,0,0,0.7)"}
            ></Box>

            <Center>
              <Box
                position={"absolute"}
                bottom={"20px"}
                color={"white"}
                fontSize={["24px", "24px", "44px"]}
                p={"32px"}
              >
                {post.title}
              </Box>
            </Center>
          </Box>

          <Flex flexDirection={["column-reverse", "column-reverse", "row"]}>
            <Box
              id="content"
              w={["100%", "100%", "60%"]}
              p={["32px", "32px", "56px"]}
              borderWidth={"0px 0px 1px 1px"}
              borderColor="#26262633"
              position={"relative"}
              minHeight={"500px"}
            >
              <Box
                overflow={"hidden"}
                h={contentHeight}
                dangerouslySetInnerHTML={{
                  __html: he.decode(post.post).toString(),
                }}
              ></Box>

              {post.post.length > 150 ? (
                <Box
                  position={"absolute"}
                  bottom={0}
                  left={0}
                  h={"215px"}
                  w="100%"
                  display={showPostCollapseButton ? "block" : "none"}
                  background={
                    "linear-gradient(180deg, rgba(20, 20, 20, 0) -19.6%, #141414 62.86%)"
                  }
                >
                  {" "}
                  <AbsoluteCenter axis="both">
                    <Button
                      ml="10px"
                      p="14px"
                      fontWeight={400}
                      background={"#141414A3"}
                      color={"#98989A"}
                      fontSize={"14px"}
                      onClick={() => {
                        setContentHeight("auto");
                        setShowPostCollapseButton(false);
                      }}
                      overflow={"hidden"}
                    >
                      Read Full blog <ArrowDownIcon ml={"10px"} />
                    </Button>
                  </AbsoluteCenter>
                </Box>
              ) : undefined}
            </Box>
            <Box w={["100%", "100%", "40%"]} border={""}>
              <Box
                p={"32px"}
                borderWidth={"0px 0px 1px 1px"}
                borderColor="#26262633"
              >
                <Flex gap={2}>
                  <Box>
                    <Flex
                      background="#1A1A1A"
                      color="#98989A"
                      px="16px"
                      py="6px"
                      borderRadius="100px"
                      h={"35px"}
                    >
                      <Box mt="2px">
                        {post.isLiked ? (
                          <FaHeart color="red" />
                        ) : (
                          <FaRegHeart />
                        )}
                      </Box>
                      <Text ml="2px" fontSize="14px" p={0}>
                        {post.likes}
                      </Text>
                    </Flex>
                  </Box>
                  <Box>
                    <Flex
                      background="#1A1A1A"
                      color="#98989A"
                      px="16px"
                      py="6px"
                      borderRadius="100px"
                      h={"35px"}
                    >
                      <Box mt="2px">
                        <FaRegComment />
                      </Box>
                      <Text  ml="2px" fontSize="14px" p={0}>
                        {post.views}
                      </Text>
                    </Flex>
                  </Box>
                  <Box>
                    <Flex
                      background="#1A1A1A"
                      color="#98989A"
                      px="16px"
                      py="6px"
                      borderRadius="100px"
                      h={"35px"}
                    >
                      <Box mt="2px">
                        <BsSend />
                      </Box>
                      <Text ml="2px" fontSize="14px" p={0}>
                        {post.shares}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box
                id="content"
                w={"100%"}
                pt={["32px", "32px", "60px"]}
                borderWidth={"0px 0px 1px 1px"}
                borderColor="#26262633"
              >
                <Flex
                  width={"100%"}
                  flexWrap={"wrap"}
                  justifyContent={"space-around"}
                >
                  <Box w={"40%"} pt={"10px"}>
                    <Text fontSize={"16px"} color={"#98989A"}>
                      Publication Date
                    </Text>
                    <Text fontSize={"16px"} color={"#0F051D"}>
                      {new Date(post.up_time).toLocaleDateString()}
                    </Text>
                  </Box>
                  <Box w={"40%"} pt={"10px"}>
                    <Text fontSize={"16px"} color={"#98989A"}>
                      Category
                    </Text>
                    <Text fontSize={"16px"} color={"#0F051D"}>
                      {post.category}
                    </Text>
                  </Box>
                  <Box w={"40%"} pt={"10px"}>
                    <Text fontSize={"16px"} color={"#98989A"}>
                      Reading Time
                    </Text>
                    <Text fontSize={"16px"} color={"#0F051D"}>
                      {post.read_time}
                    </Text>
                  </Box>
                  <Box w={"40%"} pt={"10px"}>
                    <Text fontSize={"16px"} color={"#98989A"}>
                      Author's Name
                    </Text>
                    <Text fontSize={"16px"} color={"#0F051D"}>
                      {post.author}
                    </Text>
                  </Box>
                </Flex>
                <Box padding={["16px", "16px", "32px"]}>
                  <Text fontSize={"16px"} color={"#98989A"}>
                    Table of Content
                  </Text>

                  <Box
                    mt={"14px"}
                    minHeight={"200px"}
                    padding={"20px"}
                    background={"#1A1A1A"}
                    borderRadius={"10px"}
                  >
                    <UnorderedList
                      color="#ffffff"
                      lineHeight={"48px"}
                      fontSize={"16px"}
                    >
                      {post.toc?.split(",").map((item) => (
                        <ListItem>{item.trim()}</ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      )}

      {/* <Flex justifyContent={"space-between"} p={"60px"} >
        <Heading fontSize={"22px"} color={"#cccccc"}>
          Similar News
        </Heading>
        <Link href={`#view`} pl={"50px"}>
          <Button ml="10px" px="20px" variant="primary2" borderRadius="50px">
            View All News
          </Button>
        </Link>
      </Flex>
      <Flex
        gap={"30px"}
        padding={"60px"}
        flexDirection={["column", "column", "row"]}
      >
        {similarPost.map((item) => (
          <Box>
            <Box>
              <Image src={item.image} h={["auto", "auto", "325px"]} />
            </Box>
            <Heading as={"h3"} fontSize={"16px"} pt={"16px"}>
              {item.title}
            </Heading>
            <Text color="#98989A">{item.category}</Text>
            <Flex>
              <HStack>
                <Box>
                  <Flex
                    background="#1A1A1A"
                    color="#98989A"
                    px="16px"
                    py="6px"
                    borderRadius="100px"
                  >
                    <Box mt="2px">
                      {false ? <FaHeart color="red" /> : <FaRegHeart />}
                    </Box>
                    <Text ml="2px" fontSize="14px">
                      {item.likes}
                    </Text>
                  </Flex>
                </Box>

                <Box>
                  <Flex
                    background="#1A1A1A"
                    color="#98989A"
                    px="16px"
                    py="6px"
                    borderRadius="100px"
                  >
                    <Box mt="2px">
                      <BsSend />
                    </Box>
                    <Text ml="2px" fontSize="14px">
                      {item.shares}
                    </Text>
                  </Flex>
                </Box>
              </HStack>
              <Link href={`#view`} w="20%" pl={"50px"}>
                <Button
                  ml="10px"
                  px="20px"
                  variant="primary2"
                  borderRadius="50px"
                >
                  Read more
                </Button>
              </Link>
            </Flex>
          </Box>
        ))}
      </Flex> */}
      {/* <Footer /> */}
    </Box>
  );
};
//color="#D7D7D7"
export default BlogSingle;
