import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "@firebase/firestore";

const AuthLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.emailVerified) {
        const userDocRef = doc(db, "user_kyc", user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
  
            if (!userData.gender || !userData.dob || !userData.country) {
              navigate('/kyc-data');
            } else if (!userData.phone) {
              navigate('/kyc-phone');
            } else if (!userData.frontPictureURL || !userData.backPictureURL) {
              navigate('/kyc-id');
            } else {
              navigate('/home');
            }
          } else {
            // Handle case where user_kyc does not exist
            console.log("User KYC data not found");
            // You can navigate to another page or display an error message
          }
        } catch (error) {
          console.error("Error fetching user KYC document:", error);
          // Handle error fetching document
        }
      }
    };

    fetchUserData();
  }, [user, navigate]);

  const handleButtonClick = () => {
    if (location.pathname === "/signup") {
      navigate("/login");
    } else {
      navigate("/signup");
    }
  };

  const getButtonLabel = () => {
    if (location.pathname === "/signup") {
      return "Sign in!";
    } else {
      return "Sign up!";
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box
          display={["none", "none", "flex", "flex", "flex", "flex"]}
          w="50%"
          p="30px"
          height="100vh"
          backgroundImage="url('./images/robot.png')"
          backgroundSize="cover"
          backgroundPosition="top"
          backgroundRepeat="no-repeat"
        >
          <Heading color="white" size="xl">
            Join a thriving community of creators
          </Heading>
        </Box>
        <Box p="30px" w={["100%", "100%", "80%", "50%", "50%", "50%"]}>
          <Flex justifyContent="right">
            {user ? (
              // If user is logged in, show "Go to Dashboard" button
              <Button mt="-7px" onClick={() => navigate("/home")} variant="danger">
                Go to Dashboard
              </Button>
            ) : (
              // Otherwise, show sign in/sign up toggle button
              <>
                have an account?
                <Button mt="-7px" onClick={handleButtonClick} variant="danger">
                  {getButtonLabel()}
                </Button>
              </>
            )}
          </Flex>
          <Box
            m="auto"
            w={["100%", "100%", "80%", "60%", "60%", "60%"]}
            textAlign="center"
          >
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AuthLayout;
