import {
  Box,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  useSteps,
} from "@chakra-ui/react";

import { ReactNode, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, getDoc } from "@firebase/firestore";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const KycComponent: React.FC<FormInputProps> = ({ children, index }) => {
  const steps = [
    {
      title: "Personal Information",
      showPersonalInfo: index === 0 ? false : true,
    },
    {
      title: "Verification",
      description: "",
    },
    {
      title: "Upload ID",
    },
  ];

  const { activeStep } = useSteps({
    index: index,
    count: steps.length,
  });
  const storedDataString = localStorage.getItem("kycData1");
  const kycData1: any | null = storedDataString
    ? (JSON.parse(storedDataString) as any)
    : null;

  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user && user.emailVerified) {
        const userDocRef = doc(db, "user_kyc", user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
  
            if (!userData.gender || !userData.dob || !userData.country) {
              navigate('/kyc-data');
            } else if (!userData.phone) {
              navigate('/kyc-phone');
            } else if (!userData.frontPictureURL || !userData.backPictureURL) {
              navigate('/kyc-id');
            } else {
              navigate('/home');
            }
          } else {
            // Handle case where user_kyc does not exist
            console.log("User KYC data not found");
            // You can navigate to another page or display an error message
          }
        } catch (error) {
          console.error("Error fetching user KYC document:", error);
          // Handle error fetching document
        }
      }
    };

    fetchUserData();
  }, [user, navigate]);


  return (
    <Box p="10px" h="100vh">
      <Flex>
        <Box
          display={["none", "none", "flex", "flex", "flex", "flex"]}
          w={["90%", "90%", "50%", "30%", "30%", "30%"]}
          justifyContent="center"
        >
          <Box
            bg="#FFF4F2"
            ml="-20px"
            mt="-20px"
            pt="50px"
            w="100%"
            h="101.5vh"
            pl="40px"
          >
            <Stepper
              index={activeStep}
              orientation="vertical"
              minH="20vh"
              gap="0"
              colorScheme="orange"
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0" maxW="200px">
                    <Text color="#E77817" size="sm" mb="40px">
                      {step.title}
                    </Text>
                    {step?.showPersonalInfo && (
                      <Box
                        fontSize="12px"
                        mt="-20px"
                        bg="white"
                        p="14px"
                        borderRadius="10px"
                        color="#707070"
                        fontWeight="800"
                        mb="20px"
                        w="200px"
                        boxShadow="rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"
                      >
                        <Text mb="5px">
                          {kycData1?.firstname} {kycData1?.lastname}
                        </Text>
                        <Text mb="5px">Gender: {kycData1?.gender}</Text>
                        <Text mb="5px">Date of birth: {kycData1?.dob} </Text>
                        <Text mb="5px">Country: {kycData1?.country}</Text>
                      </Box>
                    )}
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box
          w={["90%", "90%", "80%", "30%", "30%", "30%"]}
          m="auto"
          textAlign="center"
        >
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export default KycComponent;
