import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  Link,
  Grid,
  Container,
  Divider,
  VStack,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import {
  FaCommentAlt,
  FaHeart,
  FaPlus,
  FaRegComment,
  FaRegHeart,
} from "react-icons/fa";
import {
  BsArrowUpRight,
  BsArrowUpRightCircleFill,
  BsSend,
} from "react-icons/bs";
import "react-multi-carousel/lib/styles.css";
import Footer from "../components/footer";
import Header from "../components/header";
import {
  Ebook,
  ExpertContribution,
  FutureTechnologyBlog,
  GlobalLeadership,
  LatestNewsUpdate,
  ResearchInsightBlog,
  WhitePaper as Whitepapers,
} from "../svg";

import john from "../images/John Techson.png";
import sarah from "../images/Sarah Ethicist.png";
import astronomer from "../images/Astronomer X.png";
import vrwoman from "../images/woman_with_vr.png";
import whitepaperbg from "../images/whitepaperbg.png";
import downloaded from "../images/downloaded.png";
import { SlEye } from "react-icons/sl";
import { BlogPostCardComponent } from "../components/blog/postCard";
import { FaStar } from "react-icons/fa6";
import BlogHeader from "../components/blog/blogHeader";
import { useState } from "react";
import { useEffect } from "react";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 300 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 300 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 300 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Site = () => {
  const futureBlogFeature = [
    {
      title: "Quantity",
      description:
        "Over 1,000 articles on emerging tech trends and breakthroughs.",
      color: "#FF00EA",
    },
    {
      title: "Variety",
      description:
        "Articles cover fields like AI, robotics, biotechnology, and more.",
      color: "#F05A2B",
    },
    {
      title: "Frequency",
      description: "Fresh content added daily to keep you up to date.",
      color: "#2600FC",
    },
    {
      title: "Authoritative",
      description: "Written by experts and industry professionals.",
      color: "#03AEEB",
    },
  ];
  const researchInsightBlog = [
    {
      title: "Depth",
      description: "500+ research articles for in-depth understanding.",
      color: "#F05A2B",
    },
    {
      title: "Graphics",
      description: "comprehension",
      color: "#03AEEB",
    },
    {
      title: "Trends",
      description: "Explore emerging trends in technology research.",
      color: "#2600FC",
    },
    {
      title: "Contributors",
      description:
        "Contributions from blockchain tech researchers and academics.",
      color: "#FF00EA",
    },
  ];
  // const categories = [
  //   { name: "All", link: "", active: true },
  //   { name: "Quantum Computing", link: "quantum-computing" },
  //   { name: "AI Ethics", link: "aI-ethics" },
  //   { name: "Space Exploration", link: "space-exploration" },
  //   { name: "Biotechnology", link: "biotechnology" },
  //   { name: "Renewable Energy", link: "renewable-energy" },
  // ];

  // const posts = [
  //   {
  //     title: "The Quantum Leap in Computing",
  //     description:
  //       "Explore the revolution in quantum computing, its applications, and its potential impact on various industries.",
  //     createdAt: "October 15, 2023",
  //     likes: "24.5k",
  //     commentCount: "50",
  //     shareCount: "20",
  //     author: {
  //       name: "John Techson",
  //       desc: "Quantum Computing",
  //       photo: john,
  //     },
  //     isLiked: true,
  //   },

  //   {
  //     title: "The Ethical Dilemmas of AI",
  //     description:
  //       "A deep dive into ethical challenges posed by AI, including bias, privacy, and transparency.",
  //     createdAt: "November 5, 2023",
  //     likes: "32",
  //     commentCount: "72",
  //     shareCount: "18",
  //     author: {
  //       name: "Sarah Ethicist",
  //       desc: "AI Ethics",
  //       photo: sarah,
  //     },
  //   },

  //   {
  //     title: "The Mars Colonization Challenge",
  //     description:
  //       "Exploring the technical and logistical challenges of human colonization on Mars.",
  //     createdAt: "December 10, 2023",
  //     likes: "20",
  //     commentCount: "31",
  //     shareCount: "12",
  //     author: {
  //       name: "Astronomer X",
  //       desc: "Space Exploration",
  //       photo: astronomer,
  //     },
  //   },
  // ];

  const testimonials = [
    {
      author: {
        name: "Sarah Thompson",
        location: "San Francisco, USA",
        photo: john,
      },
      rating: 5,
      content:
        "The ebooks on AI in education have been a game-changer for my research. They provide in-depth insights and case studies that are invaluable for staying updated.",
    },
    {
      author: {
        name: "Raj Patel",
        location: "Mumbai, India",
        photo: john,
      },
      rating: 5,
      content:
        "The whitepapers on renewable energy strategies have greatly influenced my work. They offer detailed data and analysis.",
    },
    {
      author: {
        name: "Emily Adams",
        location: "London, UK",
        photo: john,
      },
      rating: 5,
      content:
        "The AI in healthcare reports have been an essential resource for our hospital. They highlight the latest innovations and best practices, improving patient care.",
    },
    {
      author: {
        name: "Alan Jackson",
        location: "Houston, USA",
        photo: john,
      },
      rating: 5,
      content:
        "The reports on space mining prospects have fueled my passion for space exploration. They provide a comprehensive view.",
    },
    {
      author: {
        name: "Jessica Miller",
        location: "Boston, USA",
        photo: john,
      },
      rating: 5,
      content:
        "The research papers on genomic breakthroughs have been a goldmine of information. They've shaped the direction of my research in genomics.",
    },
    {
      author: {
        name: "Diego Lopez",
        location: "Barcelona, Spain",
        photo: john,
      },
      rating: 5,
      content:
        "The ebooks on renewable energy strategies have given me the insights I needed to pivot our startup toward sustainability.",
    },
  ];

  const [blogPosts, setBlogPosts] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const [activeBlogCategory, setActiveBlogCategory] = useState(undefined);
  const [postLoading, setPostLoading] = useState(true);
  const isActiveCategory = (category:any) => category == activeBlogCategory

  const fetchBlogPosts = async (category = undefined) => {
    try {
      setPostLoading(true);
      setActiveBlogCategory(category)
      let response;
      if (!category) {
        response = await fetch("/api/blog_posts.php");
      } else {
        response = await fetch(`/api/categories/?categories=${category}`);
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogPosts(data);
      setPostLoading(false);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      setPostLoading(false);
    }
  };

  const fetchBlogCategories = async () => {
    try {
      const response = await fetch("/api/categories");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setBlogCategories(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    fetchBlogCategories();
    fetchBlogPosts();
  }, []);
  return (
    <Box backgroundAttachment="fixed">
      <Box
        _before={{
          backgroundImage: "url('./images/bg.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backdropBlur: "50%",
          backgroundAttachment: "fixed",
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.5,
          zIndex: -1,
        }}
      >
        <Header />
        <BlogHeader />
        <Box
          minH={["70vh", "70vh", "90vh", "100vh", "100vh", "100vh"]}
          backgroundPosition={[
            "bottom right",
            "bottom right",
            "bottom right",
            "bottom right",
            "bottom right",
            "bottom right",
          ]}
          // backgroundImage="url('./images/blogbg2.jpg')"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
        >
          <Flex direction={["column", "column","column", "row"]}>
            <Box p={["20px", "20px", "70px"]}>
              <Box>
                <Heading
                  fontSize={["24px","34px","72px"]}
                  fontWeight={700}
                  textAlign={["center", "center", "left"]}
                >
                  Explore The Frontiers Of Kryptonig Blog
                </Heading>
                <Text
                  my="16px"
                  color="#7B7583"
                  fontSize={["16px", "16px", "22px"]}
                  textAlign={["center", "center", "left"]}
                >
                  Your journey to tomorrow begins here
                </Text>
              </Box>
              <Box>
                <Flex justify={["center", "center", "left"]}>
                  <Box pb={"20px"}>
                    <Flex>
                      <Heading pb={"10px"} size={"xl"}>
                        300
                      </Heading>
                      <Text pt={1}>
                        <FaPlus size={30} color="#A200F2" />
                      </Text>
                    </Flex>
                    <Text color="#7B7583">Articles available</Text>
                  </Box>
                  <Box pb={"20px"} ml={"20px"}>
                    <Flex>
                      <Heading pb={"10px"} size={"xl"}>
                        3k
                      </Heading>
                      <Text pt={1}>
                        <FaPlus size={30} color="#A200F2" />
                      </Text>
                    </Flex>
                    <Text color="#7B7583">Active readers</Text>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <Box>
              <Box p={["20px", "20px", "70px"]}>
                <Image
                  w={"218px"}
                  mt={"69px"}
                  src={downloaded}
                  borderRadius={"100px"}
                  aspectRatio={"2x"}
                  textAlign={["center", "center", "left"]}

                />

                <Heading
                  mt="30px"
                  fontSize={["24px", "24px", "24px"]}
                  textAlign={["center", "center", "left"]}
                >
                  Explore 1000+ resources
                </Heading>
                <Text
                  my="30px"
                  color="#7B7583"
                  fontSize={["18px", "18px", "18px"]}
                  textAlign={["center", "center", "left"]}
                >
                  Over 1,000 articles on emerging wen3 trends and breakthroughs.
                </Text>

                <Link href={`resources`}>
                  <Button
                    px="50px"
                    py={"30px"}
                    variant="primary2"
                    borderRadius="50px"
                    w={["100%", "100%", "auto"]}
                  >
                    <Flex>
                      <Text mr={"5px"}>Explore resources</Text>{" "}
                      <BsArrowUpRight />
                    </Flex>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Flex>

          <Flex
            p={["20px", "20px", "70px"]}
            justifyContent="space-between"
            gap={10}
            direction={["column", "column", "column", "row"]}
          >
            <Box>
              <Box pb={10}> {LatestNewsUpdate}</Box>

              <Flex gap={20}>
                <Box>
                  <Heading fontSize={"xl"}>Latest News Updates</Heading>
                  <Text my="10px" color="#7B7583">
                    Stay Current
                  </Text>
                </Box>

                <BsArrowUpRightCircleFill size={"52px"} color="#8E00F3" />
              </Flex>
              <Text>Over 1,000 articles published monthly</Text>
            </Box>
            <Box>
              <Box pb={10}> {ExpertContribution}</Box>
              <Flex gap={20}>
                <Box>
                  <Heading fontSize={"xl"}>Expert Contributors</Heading>
                  <Text my="10px" color="#7B7583">
                    Trusted Insights
                  </Text>
                </Box>

                <BsArrowUpRightCircleFill size={"52px"} color="#8E00F3" />
              </Flex>
              <Text>50+ renowned Web3 experts </Text>
            </Box>
            <Box>
              <Box pb={10}> {GlobalLeadership}</Box>
              <Flex gap={20}>
                <Box>
                  <Heading fontSize={"xl"}>Global Readership</Heading>
                  <Text my="10px" color="#7B7583">
                    Worldwide Impact
                  </Text>
                </Box>

                <BsArrowUpRightCircleFill size={"52px"} color="#8E00F3" />
              </Flex>
              <Text>2 million monthly readers</Text>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        bgGradient={
          "linear-gradient(276.82deg, #EBFBFF 9.49%, rgba(255, 243, 251, 0.69) 75.79%)"
        }
        px={["32px", "32px","72px","72px", "162px"]}
        py={"88px"}
      >
        <Text
          background={"#8E00F3"}
          p={"6px"}
          my={"16px"}
          color={"#FFFFFF"}
          display={"inline-block"}
          fontSize={"20px"}
        >
          Unlock the Power of
        </Text>
        <Heading
          size="2xl"
          color="secondaryGrade"
          wordBreak={"break-word"}
          fontSize={["32px","32px","32px", "58px"]}
        >
          Kryptonig's Blog
          <Box
            bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
            bgClip="text"
            display={"inline"}
          >
            &nbsp;Features
          </Box>
        </Heading>
      </Box>
      <Flex
        p={["20px", "20px","72px", "120px"]}
        direction={["column", "column","column","row", "row"]}
      >
        <Box my="50px">
          <Box pb={10}> {FutureTechnologyBlog}</Box>
          <Heading>Future Technology Blog</Heading>
          <Text color="#98989A">
            Stay informed with our blog section dedicated to web3 technology.
          </Text>
        </Box>
        <Spacer />
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gap={"20px"}
        >
          {futureBlogFeature?.map((item) => (
            <Box
              background={item.color}
              p="30px"
              color="white"
              borderRadius={"10px"}
            >
              <Heading mb={"16px"} fontSize={"xl"}>
                {item.title}
              </Heading>
              <Text py="10px">{item.description}</Text>
            </Box>
          ))}
        </Grid>
      </Flex>
      <Divider />
      <Flex
        p={["20px", "20px","72px", "120px"]}
        direction={["column", "column","column","row", "row"]}
      >
        <Box my="50px">
          <Box pb={10}> {ResearchInsightBlog}</Box>
          <Heading>Research Insights Blogs</Heading>
          <Text color="#98989A">
            Dive deep into blockchain technology concepts with our research
            section.
          </Text>
        </Box>
        <Spacer />
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gap={"20px"}
        >
          {researchInsightBlog?.map((item) => (
            <Box
              background={item.color}
              p="30px"
              color="white"
              borderRadius={"10px"}
            >
              <Heading mb={"16px"} fontSize={"xl"}>
                {item.title}
              </Heading>
              <Text py="10px">{item.description}</Text>
            </Box>
          ))}
        </Grid>
      </Flex>

      <Box
        bgGradient={
          "linear-gradient(276.82deg, #EBFBFF 9.49%, rgba(255, 243, 251, 0.69) 75.79%)"
        }
        px={["32px", "32px","72px", "162px"]}
        py={"88px"}
      >
        <Flex
          justifyContent="space-between"
          mb={"16px"}
          direction={["column-reverse", "column-reverse", "row"]}
        >
          <Text
            background={"#8E00F3"}
            p={"6px"}
            color={"#FFFFFF"}
            display={"inline-block"}
          >
            A Knowledge Treasure Trove
          </Text>
          <Link href={`#create-blogpost`} mb={"20px"}>
            <Button
              ml="10px"
              px="20px"
              mt={"-10px"}
              variant="primary2"
              borderRadius="50px"
            >
              View All Blogs
            </Button>
          </Link>
        </Flex>
        <Heading
          size="2xl"
          color="secondaryGrade"
          wordBreak={"break-word"}
          fontSize={["32px","32px","32px", "58px"]}
        >
          Explore Kryptonig's In-Depth
          <Box
            bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
            bgClip="text"
            display={"inline"}
          >
            &nbsp;Blog Posts
          </Box>
        </Heading>
      </Box>
      <Flex
        p="40px"
        flexWrap="wrap"
        justifyContent="space-around"
        direction={["column", "column", "row"]}
      >
        <Button
          _hover={{
            bgGradient:
              "linear-gradient(105.83deg, #A700F1 -19.37%, #E31FEF 180.36%)",
            color: "white",
          }}
          p="20px"
          m="5px"
          w={["100%", "201.67", "201.67px"]}
          background={'#ffffff'}
          border="1px solid #83838340"
          borderRadius="7px"
          textAlign={"center"}
          style={isActiveCategory(undefined)?{
              background:
                "linear-gradient(105.83deg, #A700F1 -19.37%, #E31FEF 180.36%)",
              color: "white",
            }:{}}
          onClick={() => {
            fetchBlogPosts();
          }}
        >
          All
        </Button>
        {blogCategories?.map((item) => (
          <Button
            _hover={{
              bgGradient:
                "linear-gradient(105.83deg, #A700F1 -19.37%, #E31FEF 180.36%)",
              color: "white",
            }}
            style={isActiveCategory(item)?{
              background:
                "linear-gradient(105.83deg, #A700F1 -19.37%, #E31FEF 180.36%)",
              color: "white",
            }:{}}
            p="20px"
            m="5px"
            w={["100%", "201.67", "201.67px"]}
            border="1px solid #83838340"
            borderRadius="7px"
          background={'#ffffff'}
          textAlign={"center"}
            // bgGradient={
            //   item.active
            //     ? "linear-gradient(105.83deg, #A700F1 -19.37%, #E31FEF 180.36%)"
            //     : ""
            // }
            // color={item.active ? "white" : ""}
            onClick={() => {
              fetchBlogPosts(item);
            }}
          >
            <Text fontSize={"xs"} m={0}>{item}</Text>
          </Button>
        ))}
      </Flex>

      {postLoading ? (
        <Center>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            m={"10px"}
          />
        </Center>
      ) : (
        <Box>
          {blogPosts?.map((item) => (
            <BlogPostCardComponent item={item} />
          ))}
        </Box>
      )}

      <Flex
        bgGradient={
          "linear-gradient(276.82deg, #EBFBFF 9.49%, rgba(255, 243, 251, 0.69) 75.79%)"
        }
        px={["32px", "32px","72px","72px", "162px"]}
        py={"88px"}
        flexDirection={["column", "column", "row"]}
      >
        <Box>
          <Flex
          justifyContent={"space-between"}>
          <Text
            background={"#8E00F3"}
            p={"6px"}
            my={"16px"}
            color={"#FFFFFF"}
            display={"inline-block"}
            fontSize={"20px"}
          >
            Your Gateway To In-Depth Information
          </Text>

        <Center>
          <Link flexGrow={1} href={`#view`} w="20%">
            <Button ml="10px" px="20px" variant="primary2" borderRadius="50px">
              View Resources
            </Button>
          </Link>
        </Center>
          </Flex>
          <Heading
            size="2xl"
            color="secondaryGrade"
            wordBreak={"break-word"}
            fontSize={["32px","32px","32px", "58px"]}
          >
            Unlock Valuable Knowledge With Kryptonig’s
            <Box
              bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
              display={"inline"}
            >
              &nbsp;Resources
            </Box>
          </Heading>
        </Box>
      </Flex>
      <Box pt="38px">
        <Flex flexDirection={["column", "column","column", "row", "row"]}>
          <Box
            ms={["32px", "32px", "80px"]}
            pe={["32px", "32px", "60px"]}
            borderRight={["none", "none", "1px solid #26262633"]}
            w={["90%", "90%","90%", "25%", "25%"]}
            pt={"146px"}
            pr={"85px"}
          >
            <Center>
              <Box>
                <Box>{Ebook}</Box>
                <Heading mt={"30px"}>Ebook</Heading>
                <Text mb={"30px"} color="#98989A">
                  Explore our collection of ebooks covering a wide spectrum of
                  technology topics.
                </Text>
                <Link href={`/explore-resources`}>
                  <Button
                    px="50px"
                    py={"30px"}
                    variant="primary2"
                    borderRadius="50px"
                    w={'90%'}
                  >
                    <Flex>
                      <Text mr={"5px"}>Download Ebooks Now</Text>{" "}
                      <BsArrowUpRight />
                    </Flex>
                  </Button>
                </Link>
                <Flex
                  background={"#1A1A1A"}
                  border={"1px solid #262626"}
                  p={"24px"}
                  borderRadius={"8px"}
                  justifyContent={"space-around"}
                  mt={"40px"}
                  w={'90%'}
                >
                  <Box>
                    <Text color={"#98989A"} fontSize={"16px"}>
                      Downloaded By
                    </Text>
                    <Text color={"white"} fontSize={"18px"}>
                      10k + Users
                    </Text>
                  </Box>
                  <Spacer></Spacer>
                  <Image src={downloaded}  w={"50%"}  />
                </Flex>
              </Box>
            </Center>
          </Box>
          <Box p={["32px", "32px", "60px"]} w={["100%", "100%","100%", "75%", "75%"]}>
            <Text
              w={["100%", "100%", "60%"]}
              mb={"10px"}
              color="#98989A"
              float={"right"}
            >
              Topics include blockchain (25%), renewable energy (20%),
              healthcare (15%), space exploration (25%), and biotechnology
              (15%).
            </Text>
            <Image src={vrwoman} w={"100%"} />
            <Flex gap={"16px"} mt={"24px"}>
              <Box
                background={"#1A1A1A"}
                border={"1px solid #262626"}
                p={"24px"}
                borderRadius={"8px"}
                w={"35%"}
              >
                <Box>
                  <Text color={"#98989A"} fontSize={"16px"}>
                    Total Ebooks
                  </Text>
                  <Text color={"white"} fontSize={"18px"}>
                    Total Ebooks Over 100 ebooks
                  </Text>
                </Box>
              </Box>

              <Flex
                background={"#1A1A1A"}
                border={"1px solid #262626"}
                p={"24px"}
                borderRadius={"8px"}
                justifyContent={"space-around"}
                w={"65%"}
                flexDirection={["column", "column", "row"]}
              >
                <Box>
                  <Text color={"#98989A"} fontSize={"16px"}>
                    Download Formats
                  </Text>
                  <Text color={"white"} fontSize={"18px"}>
                    PDF format for access.
                  </Text>
                </Box>
                <Spacer></Spacer>
                <Link href={`#explore-resources`}>
                  <Button
                    px="10px"
                    py={"10px"}
                    borderRadius="50px"
                    background={"#141414"}
                    border={"1px solid #262626"}
                  >
                    <Flex>
                      <Text mr={"5px"} color={"#98989A"}>
                        Preview
                      </Text>{" "}
                      <SlEye color="#FF5500" />
                    </Flex>
                  </Button>
                </Link>
              </Flex>
            </Flex>

            <Box
              background={"#1A1A1A"}
              border={"1px solid #262626"}
              p={"24px"}
              borderRadius={"8px"}
              w={"100%"}
              mt={"16px"}
            >
              <Text color={"#98989A"} fontSize={"16px"}>
                Average Author Expertise
              </Text>
              <Text color={"white"} fontSize={"18px"}>
                Ebooks are authored by renowned experts with an average of 15
                years of experience
              </Text>
            </Box>
          </Box>
        </Flex>
        <Divider borderTop={"1px solid #26262633"} />

        <Flex flexDirection={["column", "column","column", "row", "row"]}>
          <Box
            ms={["32px", "32px", "80px"]}
            pe={["32px", "32px", "60px"]}
            borderRight={["none", "none", "1px solid #26262633"]}
            w={["90%", "90%","90%", "25%", "25%"]}
            pt={"146px"}
            pr={"85px"}
          >
            <Center>
              <Box>
                <Box>{Whitepapers}</Box>
                <Heading mt={"30px"}>Whitepapers</Heading>
                <Text mb={"30px"} color="#98989A">
                  Dive into comprehensive reports and analyses with our
                  collection of whitepapers.
                </Text>
                <Link href={`/coming-soon`}>
                  <Button
                    px="50px"
                    py={"30px"}
                    variant="primary2"
                    borderRadius="50px"
                    w={'90%'}
                  >
                    <Flex>
                      <Text mr={"5px"}>Download Whitepapers Now</Text>{" "}
                      <BsArrowUpRight />
                    </Flex>
                  </Button>
                </Link>
                <Flex
                  background={"#1A1A1A"}
                  border={"1px solid #262626"}
                  p={"24px"}
                  borderRadius={"8px"}
                  justifyContent={"space-around"}
                  mt={"40px"}
                  w={'90%'}

                >
                  <Box>
                    <Text color={"#98989A"} fontSize={"16px"}>
                      Downloaded By
                    </Text>
                    <Text color={"white"} fontSize={"18px"}>
                      10k + Users
                    </Text>
                  </Box>
                  <Spacer></Spacer>
                  <Image src={downloaded} w={"50%"} />
                </Flex>
              </Box>
            </Center>
          </Box>
          <Box p={["32px", "32px", "60px"]} w={["100%", "100%","100%", "75%", "75%"]}>
            <Text
              w={["100%", "100%", "60%"]}
              mb={"10px"}
              color="#98989A"
              float={"right"}
            >
              Whitepapers cover quantum computing (20%), AI ethics (15%), space
              mining prospects (20%), AI in healthcare (15%), and renewable
              energy strategies (30%).
            </Text>
            <Image src={whitepaperbg} w={"100%"} />
            <Flex gap={"16px"} mt={"24px"}>
              <Box
                background={"#1A1A1A"}
                border={"1px solid #262626"}
                p={"24px"}
                borderRadius={"8px"}
                w={"35%"}
              >
                <Box>
                  <Text color={"#98989A"} fontSize={"16px"}>
                    Total Whitepapers
                  </Text>
                  <Text color={"white"} fontSize={"18px"}>
                    Over 50 whitepapers
                  </Text>
                </Box>
              </Box>

              <Flex
                background={"#1A1A1A"}
                border={"1px solid #262626"}
                p={"24px"}
                borderRadius={"8px"}
                justifyContent={"space-around"}
                w={"65%"}
                flexDirection={["column", "column", "row"]}
              >
                <Box>
                  <Text color={"#98989A"} fontSize={"16px"}>
                    Download Formats
                  </Text>
                  <Text color={"white"} fontSize={"18px"}>
                    PDF format for access.
                  </Text>
                </Box>
                <Spacer></Spacer>
                <Link href={`/coming-soon`}>
                  <Button
                    px="10px"
                    py={"10px"}
                    borderRadius="50px"
                    background={"#141414"}
                    border={"1px solid #262626"}
                  >
                    <Flex>
                      <Text mr={"5px"} color={"#98989A"}>
                        Preview
                      </Text>{" "}
                      <SlEye color="#FF5500" />
                    </Flex>
                  </Button>
                </Link>
              </Flex>
            </Flex>

            <Box
              background={"#1A1A1A"}
              border={"1px solid #262626"}
              p={"24px"}
              borderRadius={"8px"}
              w={"100%"}
              mt={"16px"}
            >
              <Text color={"#98989A"} fontSize={"16px"}>
                Average Author Expertise
              </Text>
              <Text color={"white"} fontSize={"18px"}>
                Whitepapers are authored by subject matter experts with an
                average of 20 years of experience.
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>

      {/* <Flex
        bgGradient={
          "linear-gradient(276.82deg, #EBFBFF 9.49%, rgba(255, 243, 251, 0.69) 75.79%)"
        }
        px={["32px", "32px", "162px"]}
        py={"88px"}
        flexDirection={["column", "column", "row"]}
        justifyContent={"space-around"}
      >
        <Box>
          <Text
            background={"#8E00F3"}
            p={"6px"}
            my={"16px"}
            color={"#FFFFFF"}
            display={"inline-block"}
            fontSize={"20px"}
          >
            What Our Readers Say
          </Text>
          <Heading
            size="2xl"
            color="secondaryGrade"
            wordBreak={"break-word"}
            fontSize={"58px"}
          >
            Real Words From Real
            <Box
              bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
              display={"inline"}
            >
              &nbsp;Readers
            </Box>
          </Heading>
        </Box>
        <Center>
          <Link flexGrow={1} href={`#view`} w="20%">
            <Button ml="10px" px="20px" variant="primary2" borderRadius="50px">
              View All Testimonials
            </Button>
          </Link>
        </Center>
      </Flex>

      <Flex
        flex={"1 0 20%"}
        flexWrap={"wrap"}
        justifyContent={"space-around"}
        gap={0}
        flexDirection={["column", "column", "row"]}
        mb={"200px"}
      >
        {testimonials.map((item) => (
          <Box
            w={["100%", "100%", "33.33%"]}
            borderLeft={"1px solid #26262633"}
            borderBottom={"1px solid #26262633"}
          >
            <Box m={"40px"} p={"20px"}>
              <Center my={"40px"}>
                <Flex justifyContent={"center"}>
                  <Box>
                    <Image src={item.author.photo} mr={"10px"}></Image>
                  </Box>
                  <Box mt={"5px"}>
                    <Heading fontSize={"18px"}>{item.author.name}</Heading>
                    <Text fontSize={"16px"}>{item.author.location}</Text>
                  </Box>
                </Flex>
              </Center>
              <Center>
                <HStack
                  background={"#9704CC"}
                  p={"8px"}
                  w={"140px"}
                  borderRadius={"100px"}
                  justifyContent={"center"}
                  mb={"-15px"}
                >
                  {Array.from(Array(item.rating)).map((x, index) => (
                    <FaStar key={index} color="#FFCE22" scale={"2x"} />
                  ))}
                </HStack>
              </Center>
              <Box
                background={"#B100F1"}
                color={"white"}
                p={"24px"}
                textAlign={"center"}
                borderRadius={"12px"}
                pt={"40px"}
              >
                {item.content}
              </Box>
            </Box>
          </Box>
        ))}
      </Flex> */}

      <Footer />
    </Box>
  );
};
//color="#D7D7D7"
export default Site;
