import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { CiCircleCheck } from "react-icons/ci";
import AuthLayout from "../../components/layout/authlayout";

function AccountVerified() {
  return (
    <AuthLayout>
      <Box w="80%" m="auto" mt="100px">
        <Flex justifyContent="center" color="#209F15">
          <CiCircleCheck size="80px" />
        </Flex>

        <Text color="houseGray" mt="10px">
          Your kryptonig account has been verified, you are welcome to the
          community. You are now officially a krypto-nigga
        </Text>

        <Button w="100%" mt="60px" variant="primary">
          Create Account
        </Button>
        <Button w="100%" mt="20px" variant="secondary2">
          Setup my KYC Verification
        </Button>
      </Box>
    </AuthLayout>
  );
}

export default AccountVerified;
