import { useState } from "react";
import { Button, Heading, Text, Spinner, InputGroup, InputRightElement } from "@chakra-ui/react";
import { FormInput } from "../../components/inputs/inputs";
import AuthLayout from "../../components/layout/authlayout";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

function SignUp() {
  const [username, setUsername] = useState("");
  const [firstname, setFirstname] = useState("");
  const [othernames, setOthernames] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleSignUp = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setError("");


    if (!username || !firstname || !email || !password || !confirmPassword) {
      setError("All fields are required");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user);

      await updateProfile(user, {
        displayName: firstname + " " + othernames, // Set your desired display name here
      });

      // Add user data to Firestore
      await setDoc(doc(db, "user_kyc", user.uid), {
        uid: user.uid,
        username: username,
        email: email,
        firstname: firstname,
        othernames: othernames,
        address: "",
        backPictureURL: "",
        country: "",
        dob: "",
        frontPictureURL: "",
        gender: "",
        phone: "",
        status: "",
        timestamp: serverTimestamp()
      });

      // Clear form and error state
      setUsername("");
      setFirstname("");
      setOthernames("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setError("");

      // Redirect to email-notification page with firstname as query param
      navigate(`/email-notification?firstname=${encodeURIComponent(firstname)}`);

    } catch (err) {
      console.error("Error signing up: ", err);
      setError("Failed to create account. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <Heading size="md">Join a thriving community of creators</Heading>
      <Text color="houseGray" mt="10px">Getting started is easy</Text>
      {error && <Text color="red.500">{error}</Text>}
      <form onSubmit={handleSignUp}>
        <FormInput placeholder="First Name" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
        <FormInput placeholder="Other Names" value={othernames} onChange={(e) => setOthernames(e.target.value)} />
        
        <FormInput placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <FormInput placeholder="Enter Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />

        <InputGroup size="md">
        <FormInput placeholder="Password" value={password}
          type={show ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)} />
          <InputRightElement
          width="2.5rem"
          mt="6px"
          onClick={() => setShow(!show)}
        >
          {show ? (
            <AiFillEye color="#D9D9D9" size="26px" />
          ) : (
            <AiFillEyeInvisible color="gray" size="26px" />
          )}
        </InputRightElement>
        </InputGroup>

        <InputGroup size="md">
        <FormInput placeholder="Confirm Password" value={confirmPassword}
          type={show ? "text" : "password"}
          onChange={(e) => setConfirmPassword(e.target.value)} />
          <InputRightElement
          width="2.5rem"
          mt="6px"
          onClick={() => setShow(!show)}
        >
          {show ? (
            <AiFillEye color="#D9D9D9" size="26px" />
          ) : (
            <AiFillEyeInvisible color="gray" size="26px" />
          )}
        </InputRightElement>
        </InputGroup>

        <Button w="100%" variant="primary" type="submit" isDisabled={loading}>
          {loading ? <Spinner size="sm" /> : "Create Account"}
        </Button>
      </form>
      <Text color="houseGray" mt="10px">By continuing you indicate that you read and agreed to the Terms of Use</Text>
    </AuthLayout>
  );
}

export default SignUp;
