import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Center,
  Image,
  Link,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from 'react-router-dom'
export const BlogPostCardComponent = () => {
    const blogMenu = [
        { name: "Home", link: "/blog" },
        { name: "News", link: "/news" },
        { name: "Podcasts", link: "/podcasts" },
        { name: "Resources", link: "/resources" },
      ];
    const location = useLocation();
    const isActive = (link:string) => location.pathname === link;

  return (
   
    <Flex
    mx={["10px", "10px", "30px", "60px", "60px", "60px"]}
    py="20px"
    mt={["0px", "0px", "50px"]}
    justifyContent="center"
  >
    <Box
      display={["none", "none", "none", "flex", "flex", "flex"]}
      justifyContent="space-between"
    >
      {blogMenu?.map((item) => (
        <Link as={ReactRouterLink} to={`${item?.link}`} textDecoration="none">
          <Button
            px="18px"
            py="10px"
            color={isActive(item.link) ? "white" : "light"}
            variant="link"
            textDecoration={"none"}
            key={item?.name}
            _hover={{
              background: "black",
              color: "white",
            }}
            borderRadius={"50px"}
            bg={isActive(item.link) ? "black" : undefined}
          >
            {item?.name}
          </Button>
        </Link>
      ))}
      <Link href={`#create-blogpost`}>
        <Button ml="10px" px="20px" variant="primary2" borderRadius="50px">
          Create blogpost
        </Button>
      </Link>
    </Box>
  </Flex>
  );
};

export default BlogPostCardComponent;
