import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { ComingSoons } from "../svg";

function CommingSoon2() {
  return (
    <>
      <Box textAlign="center" color="black">
        <Center>
          <Heading pt="15vh">{ComingSoons}</Heading>
        </Center>

        <Heading mb="30px" size="lg" color="primary">
          Coming Soon
        </Heading>
        <Heading mb="30px" size="md">
            Relax and watchout!
        </Heading>
        <Button variant="primary2" onClick={() => (window.location.href = `/`)}>
          Go back to homepage
        </Button>
      </Box>
    </>
  );
}

export default CommingSoon2;
