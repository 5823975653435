import {
  Button,
  Heading,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { FormInput } from "../../components/inputs/inputs";
import AuthLayout from "../../components/layout/authlayout";
import { useState } from "react";
import { sendPasswordResetEmail } from "@firebase/auth";
import { auth } from "../../firebase";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleResetPassword = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (!email) {
      setError("Email is required");
      setLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess("Password reset email sent!");
      toast({
        title: "Success",
        description: "Password reset email sent!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmail("");
    } catch (err) {
      console.error("Error sending password reset email: ", err);
      setError("Failed to send password reset email. Please try again.");
      toast({
        title: "Error",
        description: "Failed to send password reset email. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <Heading mt="100px" size="md">
        Forgot Password?
      </Heading>
      <Text color="houseGray" mb="50px">
        Enter your email to receive a password reset link.
      </Text>
      {error && <Text color="red.500">{error}</Text>}
      {success && <Text color="green.500">{success}</Text>}
      <form onSubmit={handleResetPassword}>
        <FormInput
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="primary"
          color="#fff"
          type="submit"
          size="sm"
          isDisabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Send Password Reset Email"}
        </Button>
      </form>
    </AuthLayout>
  );
}

export default ForgotPassword;
