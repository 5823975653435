import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import KycComponent from "../../components/kycComponent";
import { useNavigate } from "react-router-dom";
import phone from "../../images/phone.png";
import "react-phone-number-input/style.css";
import { UploadPopup } from "../../components/uploadPopup";
import { onAuthStateChanged } from "@firebase/auth";
import { auth } from "../../firebase";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}

export const KycHome4 = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [image, setImage] = useState();
  const [frontPicture, setFrontPicture] = useState(null);
  const [backPicture, setBackPicture] = useState(null);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  // @ts-expect-error
  const onImageChange = (event) => {
    if (event.target.files && event.target.files) {
      // @ts-expect-error
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    setFrontPicture(event.target.files[0]);
    setFrontPicture(event.target.files[1]);
  };



  if (!user || !user.emailVerified) {
    navigate("/");
    return null; // Return null or another component while user is not verified
  }

  return (
    <KycComponent index={2}>
      <Flex justifyContent="center" mb="40px">
        <Image src={phone} />
      </Flex>

      <Heading size="md" mb="20px">
        Upload Document
      </Heading>
      <Text fontSize="13px" color="houseGray" mb="30px">
        Kindly upload a means of identification to keep your activity safe and
        secure on our platform
      </Text>

      <Button
        onClick={onOpen}
        w="100%"
        fontWeight="600"
        mb="30px"
        variant="secondary2"
      >
        National Identity Card
      </Button>
      <Button
        onClick={onOpen}
        w="100%"
        fontWeight="600"
        mb="30px"
        variant="secondary2"
      >
        International Passport
      </Button>
      <Button
        onClick={onOpen}
        w="100%"
        fontWeight="600"
        mb="30px"
        variant="secondary2"
      >
        Driver’s License
      </Button>
      <UploadPopup isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </KycComponent>
  );
};

export default KycHome4;
