import { Button, Flex, Heading, InputGroup, InputRightElement, Text, useToast, Spinner } from "@chakra-ui/react";
import { FormInput } from "../../components/inputs/inputs";
import AuthLayout from "../../components/layout/authlayout";
import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { auth, db } from "../../firebase";
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const handleReset = () => {
    window.open('/forgot-password', '_self');
  };

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        const userDocRef = doc(db, "user_kyc", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          if (!userData.gender || !userData.dob || !userData.country) {
            navigate('/kyc-data');
          } else if (!userData.phone) {
            navigate('/kyc-phone');
          } else if (!userData.frontPictureURL || !userData.backPictureURL) {
            navigate('/kyc-id');
          } else {
            navigate('/home');
          }
        } else {
          console.error("No such document!");
        }
      } else {
        await sendEmailVerification(user);
        navigate(`/email-notification?firstname=${encodeURIComponent(user.displayName || "User")}`);
      }
    } catch (err) {
      console.error("Error logging in: ", err);
      setError("Failed to log in. Please check your email and password.");
      toast({
        title: "Error",
        description: "Failed to log in. Please check your email and password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <Heading mt="100px" size="md">
        Welcome Back!
      </Heading>
      <Text color="houseGray" mb="50px">
        Welcome back! Please enter your details
      </Text>
      {error && <Text color="red.500" mb="20px">{error}</Text>}
      <form onSubmit={handleLogin}>
        <FormInput
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputGroup size="md">
          <FormInput
            isRequired={true}
            placeholder="Password"
            type={show ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <InputRightElement
            width="2.5rem"
            mt="6px"
            onClick={() => setShow(!show)}
          >
            {show ? (
              <AiFillEye color="#D9D9D9" size="26px" />
            ) : (
              <AiFillEyeInvisible color="gray" size="26px" />
            )}
          </InputRightElement>
        </InputGroup>
        <Flex justifyContent="right" mb="40px" mt="-20px">
          <Button variant="secondary3" color="#F24216" size="sm" onClick={handleReset}>
            Forgot Password?
          </Button>
        </Flex>
        <Button w="100%" variant="primary" type="submit" isDisabled={loading}>
          {loading ? <Spinner size="sm" /> : "Login"}
        </Button>
      </form>
    </AuthLayout>
  );
}

export default Login;
