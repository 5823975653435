import { background, extendTheme } from "@chakra-ui/react";
import { buttonStyle as Button } from "./buttonStyle";
import { color } from "framer-motion";
export const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },

  fonts: {
    heading: `Poppins, sans-serif`,
    body: `Poppins, sans-serif`,
  },

  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label":
              {
                transform: "scale(0.85) translateY(-24px)",
                color: "#000000",
                borderRadius: "5px",
              },
            label: {
              color: "#C4C4C4",
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },

    Button,
  },
  colors: {
    primary: "linear-gradient(196.53deg, #ED7662 -20.84%, #3E77CD 119.62%)",
    primary2: "linear-gradient(214.02deg, #F1765F 6.04%, #1478E6 92.95%)",
    secondary: "#ED7662",
    houseGray: "#7E7E7E",
    secondaryGrade:
      "linear-gradient(62.05deg, #2600FC 0%, rgba(255, 40, 4, 0.65) 100%)",
    primaryGrade:
      "linear-gradient(97deg, #328913 17.55%, rgba(74, 207, 27, 0.85) 71.21%)",

    backgroundColors: "white",
  },
});
