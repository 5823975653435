import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { ComingSoons } from "../svg";

function CommingSoon() {
  const getLastPath = () => {
    const pathArray = window.location.pathname.split('/');
    return pathArray[pathArray.length - 1];
  };
  return (
    <>
      <Box textAlign="center" color="black">
        <Center>
          <Heading pt="15vh">{ComingSoons}</Heading>
        </Center>

        <Heading mb="30px" size="lg" color="primary">
          Coming Soon
        </Heading>
        <Heading mb="30px" size="md">
          The kryptonig {getLastPath()} is coming soon. Relax and watchout!
        </Heading>
        <Button variant="primary2" onClick={() => (window.location.href = `/`)}>
          Go back to homepage
        </Button>
      </Box>
    </>
  );
}

export default CommingSoon;
