import { useNavigate } from "react-router-dom";

import {
  Box,
  Flex,
  Button,
  DrawerContent,
  Image,
  Link,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  Divider,
} from "@chakra-ui/react";

import { FaTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTelegram,
} from "react-icons/fa";
import { ReactNode, useRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import logos from "../images/logos.png";
import { FaWallet } from "react-icons/fa";
import { HamburgerIcon } from "@chakra-ui/icons";

export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const KycComponent: React.FC<FormInputProps> = ({ children, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const menu = [
    { name: "Home", link: "/" },
    { name: "Showroom", link: "#showroom" },
    { name: "Why Choose Us ", link: "/#choose" },
    { name: "Contributor", link: "#" },
    { name: "Roadmap", link: "/#roadmap" },
    { name: "Blog", link: "/blog" },
  ];
  const blogMenu = [
    { name: "News", link: "/news" },
    { name: "Podcasts", link: "/podcasts" },
    { name: "Resources", link: "/resources" },
  ];
  return (
    <Flex
      mx={["10px", "10px", "30px", "60px", "60px", "60px"]}
      py="20px"
      justifyContent="space-between"
    >
      <Box>
      <Link as={ReactRouterLink} to={`/`}>

        <Image w="200px" src={logos} />
        </Link>
      </Box>
      <Box
        display={["none", "none", "none", "flex", "flex", "flex"]}
        justifyContent="space-between"
      >
        {menu?.map((item) => (
          <Link href={`${item?.link}`} key={item.name}>
            <Button px="20px" fontSize={"18px"} color="black" variant="link" key={item?.name}>
              {item?.name}
            </Button>
          </Link>
        ))}
      </Box>
      <Box
        display={["none", "none", "none", "flex", "flex", "flex"]}
        justifyContent="space-between"
        mr={"32px"}
        mt="5px"
        w="10%"
        gap={"15px"}
      >
        <Link href="https://www.facebook.com/profile.php?id=61563282131563&mibextid=ZbWKwL" target="_blank">
          <FaFacebookF size="25px" />
        </Link>
        <Link href="https://x.com/KryptonigXYZ?t=bewS4WuMMm0ZWTMpvSJlzA&s=09" target="_blank">
          <FaTwitter size="25px" />
        </Link>
        <Link href="https://www.instagram.com/kryptonigxyz?igsh=MTRwbDFpMHdlaGNtbQ==" target="_blank">
          <FaInstagram size="25px" />
        </Link>
        <Link href="https://www.linkedin.com/posts/kryptonig-llc_kryptonig-llc-linkedin-activity-7265342685979279360-xMQ_?utm_source=share&utm_medium=member_android" target="_blank">
          <FaLinkedinIn size="25px" />
        </Link>
        <Link href="https://t.me/kryptonigXYZ" target="_blank">
          <FaTelegram size="25px" />
        </Link>
      </Box>
      <Box display={["grid", "grid", "grid", "none", "none", "none"]}>
        <Button onClick={onOpen} variant="primary" h="auto">
          <HamburgerIcon />
        </Button>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xs"
      >
        <DrawerOverlay />
        <DrawerContent textAlign="left">
          <DrawerCloseButton />
          <Box mt="70px">
            {menu?.map((data) => (
              <Box key={data?.name}>
                <Button
                  variant="transpare"
                  onClick={() => {
                    navigate(data?.link);
                    onClose();
                  }}
                >
                  {data?.name}
                </Button>
                <br></br>
              </Box>
            ))}
            <Divider></Divider>
            {blogMenu?.map((data) => (
              <Box key={data?.name}>
                <Button
                  variant="transpare"
                  onClick={() => {
                    navigate(data?.link);
                    onClose();
                  }}
                >
                  {data?.name}
                </Button>
                <br></br>
              </Box>
            ))}
          </Box>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default KycComponent;
