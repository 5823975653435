import { Box, Button, Flex, Text } from "@chakra-ui/react";
import AuthLayout from "../../components/layout/authlayout";
import { Docs } from "../../svg";
import { useLocation } from "react-router-dom";

function EmailNotification() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const firstname = params.get("firstname") || "User";

  
  const handleSignup = () => {
    window.open('/login', '_self');
  };

  return (
    <AuthLayout>
      <Box w="80%" m="auto" mt="100px">
        <Flex justifyContent="center">{Docs}</Flex>
        <Text color="houseGray" mt="10px">
          Hey {firstname}! We sent you an email. Please check your mailbox to verify your account.
        </Text>
        <Button w="100%" mt="60px" onClick={handleSignup} variant="primary">Login</Button>
      </Box>
    </AuthLayout>
  );
}

export default EmailNotification;
