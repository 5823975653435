import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  useDisclosure,
} from "@chakra-ui/react";
import { BiPlusCircle } from "react-icons/bi";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box1 } from "../components/boxes";
import { NewUploadPopup } from "../components/newPorjectPopup";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const Home = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const datas = {
      dob: dob,
      firstname: firstname,
      gender: gender,
      country: country,
      lastname: lastname,
    };
    localStorage.setItem("kycData1", JSON.stringify(datas));
    navigate("/kyc2");
  };
  return (
    <>
      <Heading size="sm" m="0px" textDecoration="underline">
        Get started with Kryptonig
      </Heading>
      <Flex>
        <Box1
          title="KYC"
          bname="Start"
          pvalue={30}
          text=" Verify your identity with us   Less than 10 minutes"
        />
        <Box1
          title="Create your project"
          bname="Start"
          pvalue={60}
          text="Create your first live project in few minutes"
        />
        <Box1
          title="Fund project"
          bname="Start Project"
          text="Contribute to earn by funding creators project that excites you"
        />
      </Flex>
      <Heading size="sm" mb="20px" textDecoration="underline">
        Projects
      </Heading>
      <Center
        onClick={onOpen}
        cursor="pointer"
        w="200px"
        h="200px"
        bg="#D7D7D7"
        borderRadius="15px"
      >
        <Heading size="sm">
          <Center mt="-20px" mb="-10px">
            <BiPlusCircle size="30px" />
          </Center>
          <br />
          Add new project
        </Heading>
      </Center>
      <Heading size="sm" mt="20px" textDecoration="underline">
        Community Tips
      </Heading>
      <Flex>
        <Box1
          title="View tips"
          bname="See tips"
          pvalue={30}
          text="Learn how to participate in our community funding. Know our rules and regulations that promote stable ecosystem"
        />
        <Box1
          title="Join our community"
          bname="Join community"
          pvalue={60}
          text="Join our community to contribute to an ecosystem that promotes positive change to our world"
        />
      </Flex>

      <NewUploadPopup isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};
//color="#D7D7D7"
export default Home;
