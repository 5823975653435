import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaArrowRightLong } from "react-icons/fa6";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlOptions } from "react-icons/sl";
import { Box2 } from "../components/boxes";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const ProjectDetails = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const datas = {
      dob: dob,
      firstname: firstname,
      gender: gender,
      country: country,
      lastname: lastname,
    };
    localStorage.setItem("kycData1", JSON.stringify(datas));
    navigate("/kyc2");
  };
  return (
    <>
      <Flex p="20px" justifyContent="space-between" borderRadius="20px">
        <Box w="62%" fontSize="12px">
          <Box
            w="65%"
            p="25px"
            position="relative"
            h="200px"
            bg="#D9D9D9"
            borderRadius="20px"
          >
            <Heading size="md" position="absolute" bottom="4">
              Project Name
            </Heading>
          </Box>
          <Flex w="65%" justifyContent="space-between" fontSize="13px" p="20px">
            <Box>
              Votes
              <Text fontWeight="900">10/20</Text>
            </Box>
            <Box>
              Budget
              <Text fontWeight="900">$2,500</Text>
            </Box>
            <Box>
              Project Milestones
              <Text textAlign="center" fontWeight="900">
                3
              </Text>
            </Box>
            <Button
              border="1px solid #D2CECE"
              bg="transparent"
              size="sm"
              borderRadius="30px"
              color="#5546FF"
            >
              In Progress
            </Button>
          </Flex>
          <Flex>
            <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
            <Text mt="3px" ml="15px" fontSize="13px">
              <b> John Doe</b> <br />
              March 5. 2023
            </Text>
          </Flex>

          <Button mt="20px" variant="primary" w="60%" size="sm">
            Vote
          </Button>

          <Heading mt="30px" size="sm">
            Project Details
          </Heading>
          <Text color="#1F1F1F80">
            Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex.
            Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum
            lorem.{" "}
          </Text>
        </Box>
        <Box w="35%">
          <Box border="1px solid #D2CECE" borderRadius="30px" p="20px">
            Voters (10/200)
            {[1, 2, 3, 4, 6].map(() => (
              <Flex justifyContent="space-between" mt="20px">
                <Flex>
                  <Avatar
                    name="Ryan Florence"
                    src="https://bit.ly/ryan-florence"
                  />
                  <Text mt="10px" ml="15px">
                    John Doe
                  </Text>
                </Flex>
                <Text color="#666B78" mt="10px">
                  5:40pm
                </Text>
              </Flex>
            ))}
          </Box>
          <Box
            border="1px solid #D2CECE"
            borderRadius="30px"
            p="20px"
            mt="50px"
          >
            <Heading size="sm">Milestone</Heading>
            {[1, 2, 3, 4, 6].map(() => (
              <Flex justifyContent="space-between" mt="20px">
                <Flex>
                  <Avatar name="1" />
                  <Text mt="" ml="15px">
                    John Doe <Text color="#A9ABBD">2 hrs ago</Text>
                  </Text>
                </Flex>
                <Text color="#666B78" mt="10px">
                  View
                </Text>
              </Flex>
            ))}
          </Box>
        </Box>
      </Flex>
    </>
  );
};
//color="#D7D7D7"
export default ProjectDetails;
