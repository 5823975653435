import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import SignUp from "./pages/auth/signUp";
import EmailNotification from "./pages/auth/emailNotification";
import AccountVerified from "./pages/auth/accountVerified";
import KycHome from "./pages/kyc";
import KycHome2 from "./pages/kyc/index2";
import KycHome3 from "./pages/kyc/index3";
import KycHome4 from "./pages/kyc/index4";
import KycSuccess from "./pages/kyc/success";
import Login from "./pages/auth/login";
import { DashboardLayout } from "./components/layout/dashboardLayout";
import Home from "./pages/home";
import ProjectView from "./pages/projectView";
import ProjectDetails from "./pages/projectDetails";
import Profile from "./pages/profile";
import Rewards from "./pages/reward";
import Site from "./pages/site";
import Blog from "./pages/blog"
import ForgotPassword from "./pages/auth/forgotPassword";
import News from "./pages/news"
import Podcasts from "./pages/podcasts"
import BlogSingle from "./pages/blogSingle"
import Resources from "./pages/resources"
import CommingSoon from "./pages/comingSoon";
import CommingSoon2 from "./pages/comingSoon2";
import NotFound from "./pages/notFound";
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Site />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/posts/:id" element={<BlogSingle />} />
      <Route path="/news" element={<CommingSoon />} />
      <Route path="/podcasts" element={<CommingSoon />} />
      <Route path="/resources" element={<CommingSoon />} />
      <Route path="/email-notification" element={<EmailNotification />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/account-verified" element={<AccountVerified />} />
      <Route path="profile" element={<CommingSoon2 />} />
      <Route path="project-details" element={<CommingSoon2 />} />
      <Route path="rewards" element={<CommingSoon2 />} />
      <Route path="faqs" element={<CommingSoon2 />} />
      <Route path="settings" element={<CommingSoon2 />} />
      <Route path="community" element={<CommingSoon2 />} />

      <Route path="/kyc-data" element={<KycHome />} />
      <Route path="/kyc-phone" element={<KycHome2 />} />
      {/* <Route path="/kyc3" element={<KycHome3 />} /> */}
      <Route path="/kyc-id" element={<KycHome4 />} />
      <Route path="/kyc-success" element={<KycSuccess />} />
      <Route path="/home" element={<DashboardLayout />}>
        <Route path="" element={<Home />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="projectview" element={<ProjectView />} />
        <Route path="profile" element={<Profile />} />
        <Route path="project-details" element={<ProjectDetails />} />
        <Route path="rewards" element={<Rewards />} />
      </Route>

      <Route path="/coming-soon" element={<CommingSoon />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/showroom" element={<CommingSoon />} />

    </Route>
  )
);
