import {
  Input,
  FormControl,
  FormErrorMessage,
  Select,
  InputGroup,
  InputLeftElement,
  Box,
  Heading,
  Text,
  Progress,
  Flex,
  Button,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { SlOptions } from "react-icons/sl";
export interface BoxesProps {
  text?: any;
  title?: string;
  pvalue?: number;
  bname?: string;
  children?: ReactNode;
  vote?: string;
  // error?: { [key: string]: any };
}

export const Box1: React.FC<BoxesProps> = ({
  children,
  title,
  text,
  bname,
  pvalue,
}) => {
  return (
    <Box
      fontSize="10px"
      m="10px"
      my="15px"
      p="20px"
      w="250px"
      border="1px solid #D7D7D7"
      borderRadius="10px"
    >
      <Heading size="sm">{title}</Heading>
      <Text my="10px">{text}</Text>
      {children}
      {pvalue && (
        <Flex>
          <Progress
            borderRadius="5px"
            h="10px"
            colorScheme="blue"
            size="sm"
            w="100%"
            value={pvalue}
          />

          <Text m="5px" mt="-4px" fontSize="13px">
            {pvalue}%
          </Text>
        </Flex>
      )}
      <Text>Less than 3 minutes</Text>
      <Button h="32px" w="100%" my="10px" variant="primary">
        {bname}
      </Button>
    </Box>
  );
};
export const Box2: React.FC<BoxesProps> = ({ title, text, vote }) => {
  return (
    <Box
      border="1px solid #D2CECE"
      borderRadius="10px"
      p="15px"
      w="48%"
      m="10px"
    >
      <Flex justifyContent="space-between">
        <Button
          border="1px solid #D2CECE"
          bg="transparent"
          size="sm"
          borderRadius="30px"
          color="#00DEA3"
        >
          Completed
        </Button>
        <SlOptions size="25px" />
      </Flex>
      <Heading my="10px" size="sm">
        {title}
      </Heading>
      <Text mb="10px">{vote}</Text>
      <Text>{text}</Text>
      <Flex justifyContent="space-between">
        <AvatarGroup size="md" max={5}>
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
          <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
          <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
          <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
        </AvatarGroup>
        <Box mt="20px">
          <FaArrowRightLong size="20px" />
        </Box>
      </Flex>
    </Box>
  );
};
