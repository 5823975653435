import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { Notfound404 } from "../svg";

function NotFound() {
  return (
    <>
      <Box textAlign="center" color="black">
        <Center>
          <Heading pt="15vh">{Notfound404}</Heading>
        </Center>

        <Heading mb="30px" size="lg" color="primary">
          Error 404
        </Heading>
        <Heading mb="30px" size="md">
          Oops! Page Not Found
        </Heading>
        <Button variant="primary2" onClick={() => (window.location.href = `/`)}>
          Go home
        </Button>
        <Text>
          Sorry, but the page you are looking for is not found. Please make sure
          you have typed the correct URL.
        </Text>
      </Box>
    </>
  );
}

export default NotFound;
