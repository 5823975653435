import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { ReactNode, useState, useEffect } from "react";
import KycComponent from "../../components/kycComponent";
import { useNavigate } from "react-router-dom";
import phoneImage from "../../images/phone.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, db } from "../../firebase";
import { doc, updateDoc } from "@firebase/firestore";

export const KycHome2 = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!phone) {
      console.error("Please fill your phone number.");
      setError("Please fill your phone number.");
      return;
    }

    setLoading(true); // Start loading

    const datas = {
      phone: phone,
    };

    try {
      const userDocRef = doc(db, "user_kyc", user.uid);
      await updateDoc(userDocRef, datas);
      navigate("/kyc-id");
    } catch (error) {
      console.error("Error updating document: ", error);
      setError("Failed to update phone number.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handlePhoneChange = (value: string | undefined) => {
    setPhone(value || ""); // Update the phone state with the input value or empty string if undefined
  };

  if (!user || !user.emailVerified) {
    navigate("/");
    return null; // Return null or another component while user is not verified
  }

  return (
    <KycComponent index={1}>
      <Flex justifyContent="center">
        <Image src={phoneImage} />
      </Flex>

      <Heading size="md">Verify your phone number</Heading>
      <Text fontSize="12px" color="gray.600" mb="10px">
        To secure your account, confirm your phone number with the code that
        will be sent to your phone
      </Text>

      <form onSubmit={submitHandler}>
        <Box mb="40px">
          <PhoneInput
            required={true}
            defaultCountry="NG"
            placeholder="Enter phone number"
            value={phone}
            onChange={handlePhoneChange}
          />
        </Box>

        <Button w="100%" variant="primary" type="submit" disabled={loading}>
          {loading ? <Spinner size="sm" /> : "Next"}
        </Button>
      </form>

      {error && (
        <Text color="red.500" mt="4">
          {error}
        </Text>
      )}
    </KycComponent>
  );
};

export default KycHome2;
