import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Drawer,
  Flex,
  Heading,
  Image,
  Input,
  Spacer,
  Text,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link,
} from "@chakra-ui/react";
import art1 from "../images/art_03.png";
import nft from "../images/nft.jpg";
import sell from "../images/sell.jpg";
import btcwallet from "../images/btc.jpg";
import logos from "../images/logos.png";
import { useNavigate } from "react-router-dom";
import { FaTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { GoArrowUpRight } from "react-icons/go";
import { RxPlusCircled } from "react-icons/rx";
import { AiOutlineMinusCircle } from "react-icons/ai";
import Faq from "react-faq-component";
import { useRef } from "react";
import {
  BitGo,
  Coinbase,
  Community,
  Correct,
  Docc,
  Exodus,
  MetaMask,
  Rewardds,
  TrustWallet,
  Zige,
} from "../svg";
import { HamburgerIcon } from "@chakra-ui/icons";
import Header from "../components/header";
import Footer from "../components/footer";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 300 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 300 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 300 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const Site = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const menu = [
    { name: "Home", link: "" },
    { name: "Showroom", link: "showroom" },
    { name: "Why Choose Us ", link: "choose" },
    { name: "Contributor", link: "contributor" },
    { name: "Roadmap", link: "roadmap" },
    { name: "Blog", link: "" },
  ];
  const roadmap = [
    {
      id: "01",
      name: "Planning",
      text: "Quality comes first. we took our time to plan out everything and build our production pipeline for an insured transparent blockchain.",
      steps: [
        "Release website and logo",
        "Grow community",
        "Launch the project",
      ],
    },
    {
      id: "02",
      name: "Onboarding",
      text: "Onboarding early users, launching the blog and reward feature.",
      steps: [
        "Onboard early users",
        "Launch the blog",
        "Launch the reward feature",
      ],
    },
    {
      id: "03",
      name: "Forum",
      text: "First community airdrop for early users and launch of the forum feature",
      steps: ["Community airdrop", "Launch the forum feature"],
    },
    {
      id: "04",
      name: "Showroom",
      text: "Launch of the showroom, DAO LP feature and further development",
      steps: [
        "Launch showroom",
        "Launch DAO LP feature",
        "Further development",
      ],
    },
  ];
  const why = [
    {
      name: "Access to capital",
      icon: Docc,
      text: "Create a liquidity pool and set your own funding rules",
    },
    {
      name: "Contribute to earn",
      icon: Correct,
      text: "An opportunity for investors and contributors to be part of the creator’s journey and earn",
    },
    {
      name: "The right audience",
      icon: Rewardds,
      text: "Marketing is the big deal between starting and finishing a product. This is where it all falls apart. Showcase your project and bring it to the spotlight",
    },
    {
      name: "Big community",
      icon: Community,
      text: "Be part of a community of creators-contributors symbiotic relationship achieving dreams and goals together",
    },
  ];
  const whyInfo = [
    {
      name: "80k+",
      text: "Investors and contributorss",
    },
    {
      name: "20k+",
      text: "Projects",
    },
    {
      name: "230+",
      text: "Creators",
    },
  ];
  const config = {
    animate: true,
    expandIcon: <RxPlusCircled />,
    collapseIcon: <AiOutlineMinusCircle />,
  };
  const faqdata = {
    rows: [
      {
        title: "What are they?",
        content: `Custom songs are songs requested by our music lovers to be written and recorded by our featured Industry Nite artists, each custom song is an original personalized story to you. Our artists will maintain a standard song structure of 1:00 - 2:30 minutes in length which includes 2 versus and a chorus. The songs created by our artists are 100% owned by them, and we do not grant song ownership to anyone outside of the artist that created it.`,
      },
      {
        title: "How do I put in a song request?",
        content: `Click on the link and it will take you to a form where you can fill out details about your custom song.`,
      },
      {
        title: "How long does it take to deliver my song?",
        content:
          "Once your song is ready, you will get an email with the URL to the song for you to listen to. The song will be ready in 4-7 business days??",
      },
      {
        title: "How will i receive my song?",
        content: `On your song page you can listen to the song, view the lyrics and learn more about the artist who did your song. You can also share the link to your friends and family.`,
      },
      {
        title: "How do you create my custom song?",
        content: `Each song is created 100 percent based on the submission you have provided. Instrumentals and melodies may be re-purposed by same artists across different songs based on customers request to ensure best possible song match.`,
      },
    ],
  };
  const accountData = ["About", "Show Room ", "RoadMap", "Faq"];
  const community = ["How it works!", "Blockchain", "Get in touch"];
  const handleCreate = () => {
    window.open('/signup', '_blank');
  };
  const handleShowroom = () => {
    window.open('/showroom', '_self');
  };
  return (
    <>
      <Box
        minH={["70vh", "70vh", "90vh", "100vh", "100vh", "100vh"]}
        backgroundImage="url('./images/bgbg2.jpg')"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundAttachment="fixed"
        backgroundPosition={[
          "bottom right",
          "bottom right",
          "bottom right",
          "bottom right",
          "bottom right",
          "bottom right",
        ]}
      >
        <Header />
        <Box
          p={["20px", "20px", "70px", "70px", "70px", "70px"]}
          w={["95%", "95%", "80%", "40%", "40%", "40%"]}
        >
          <Heading>Create And Get Crowdfunded The Smarter Way</Heading>
          <Text my="30px" color="#7B7583">
            With our solutions, you can build a DAO of contributors and reward
            system
          </Text>
          <Button
            rightIcon={<GoArrowUpRight />}
            borderRadius="20px"
            variant="primary2"
            onClick={handleCreate}
            
          >
            Create your project
          </Button>
          <AvatarGroup my="20px" size="md" max={2}>
            <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
            <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
            <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
            <Avatar
              name="Prosper Otemuyiwa"
              src="https://bit.ly/prosper-baba"
            />
            <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
          </AvatarGroup>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" justifyContent="space-evenly">
        <Box p="60px"> {MetaMask}</Box>
        <Box p="60px"> {BitGo}</Box>
        <Box p="60px"> {Coinbase}</Box>
        <Box p="60px"> {TrustWallet}</Box>
        <Box p="60px"> {Exodus}</Box>
      </Box>
      <Center id="showroom" my="60px">
        {Zige}
      </Center>
      <Heading
        size="2xl"
        display="flex"
        justifyContent="center"
        color="secondaryGrade"
      >
        Kryptonig's
        <Box
          bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
          bgClip="text"
        >
          &nbsp;showroom
        </Box>
      </Heading>
      <Text
        m="auto"
        w={["90%", "90%", "80%", "28%", "28%", "28%"]}
        textAlign="center"
        my="30px"
        color="#7B7583"
      >
        Contributors and investors like you use the ShowTV to find promising
        project to fund and earn
      </Text>
      <Box ml="90px">
        <Carousel
          responsive={responsive}
          showDots={true}
          dotListClass="custom-dot-list-style"
        >
          {[2, 5, 6, 2, 5, 6, 7].map(() => (
            <Box mb="50px">
              <Image src={art1} />
              <Heading mt="20px" size="md">
                #Metaverse
              </Heading>
              <Text fontSize="12px" color="#7B7583">
                By TheSalvare
              </Text>
            </Box>
          ))}
        </Carousel>
        <Center>
          <Button
            rightIcon={<GoArrowUpRight />}
            borderRadius="20px"
            variant="primary2"
            my="60px"
            ml="-80px"
            onClick={handleShowroom}
          >
            View showroom
          </Button>
        </Center>
      </Box>
      <Center id="choose" my="60px">
        {Zige}
      </Center>
      <Heading
        size="2xl"
        display="flex"
        justifyContent="center"
        color="secondaryGrade"
      >
        Why
        <Box
          bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
          bgClip="text"
        >
          &nbsp;choose us?
        </Box>
      </Heading>
      <Flex p="40px" flexWrap="wrap" justifyContent="space-evenly">
        {why?.map((item) => (
          <Box
            w={["90%", "90%", "45%", "20%", "20%", "20%"]}
            p="40px"
            m="20px"
            border="1px solid #83838340"
            borderRadius="10px"
          >
            {item?.icon}
            <Heading mt="15px" size="sm">
              {item?.name}
            </Heading>
            <Text my="10px" color="#7B7583">
              {item?.text}
            </Text>
          </Box>
        ))}
      </Flex>{" "}
      <Center my="50px">{Zige}</Center>
      <Flex p="40px" flexWrap="wrap" justifyContent="space-evenly">
        {whyInfo?.map((item) => (
          <Box
            w={["90%", "90%", "45%", "20%", "20%", "20%"]}
            p="40px"
            borderRight="1px solid #83838340"
            borderRadius="10px"
            textAlign="center"
          >
            <Heading mt="15px" size="2xl">
              {item?.name}
            </Heading>
            <Text my="10px" fontSize="13px" color="#7B7583">
              {item?.text}
            </Text>
          </Box>
        ))}
        <Box
          w={["90%", "90%", "45%", "20%", "20%", "20%"]}
          p="40px"
          borderRadius="10px"
          textAlign="center"
        >
          <Heading mt="15px" size="2xl">
            2.5x
          </Heading>
          <Text my="10px" fontSize="13px" color="#7B7583">
            Estimated value
          </Text>
        </Box>
      </Flex>
      <Center my="50px">{Zige}</Center>
      <Heading
        size="2xl"
        display="flex"
        justifyContent="center"
        color="secondaryGrade"
      >
        How it
        <Box
          bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
          bgClip="text"
        >
          &nbsp;works!
        </Box>
      </Heading>
      <Flex justifyContent="center" flexWrap="wrap" m="auto" w="90%" mt="70px">
        <Box w={["90%", "90%", "55%", "55%", "55%", "55%"]}>
          <Center>
            <Image maxW="400px" src={btcwallet} />
          </Center>
        </Box>
        <Box w={["90%", "90%", "45%", "45%", "45%", "45%"]} mt="40px">
          <Box w="90%">
            <Heading
              bgGradient="linear-gradient(30.32deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
            >
              01.
            </Heading>
            <Heading size="2xl">Setup and connect your wallet.</Heading>
            <Text mt="20px" color="#7B7583">
              Use Trust Wallet, Metamask or any wallet to connect to multiple
              chains.
              <br />
              <br />
            </Text>
            <Text color="#7B7583">
              Contributors pay a minimum of $1 to your wallet to join your
              community and crowdfund your project. Your wallet is where your
              funds are stored.
            </Text>
            <Button
              mt="20px"
              rightIcon={<GoArrowUpRight />}
              borderRadius="20px"
              variant="link"
              onClick={handleCreate}

            >
              Create your project
            </Button>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="center" flexWrap="wrap" m="auto" w="90%" mt="70px">
        <Box w={["90%", "90%", "45%", "45%", "45%", "45%"]} mt="40px">
          <Box w="90%">
            <Heading
              bgGradient="linear-gradient(30.32deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
            >
              02.
            </Heading>
            <Heading size="2xl">Create your own digital project</Heading>
            <Text mt="20px" color="#7B7583">
              Quality comes first. we took our time to plan out everything and
              build our production pipeline for an insured transparent
              blockchain. Bring your project to life by showcasing it and
              getting crowdfunded. <br /> <br />
            </Text>
            <Text color="#7B7583">
              Starting the production on the procedurally automated system
              powered by smart contract. Create a forum and start your own
              community here
            </Text>
            <Button
              mt="20px"
              rightIcon={<GoArrowUpRight />}
              borderRadius="20px"
              variant="link"
            >
              Create your project
            </Button>
          </Box>
        </Box>
        <Box w={["90%", "90%", "55%", "55%", "55%", "55%"]}>
          <Center>
            <Image maxW="400px" src={nft} />
          </Center>
        </Box>
      </Flex>{" "}
      <Flex justifyContent="center" flexWrap="wrap" m="auto" w="90%" mt="70px">
        <Box w={["90%", "90%", "55%", "55%", "55%", "55%"]}>
          <Center>
            <Image maxW="400px" src={sell} />
          </Center>
        </Box>
        <Box w={["90%", "90%", "45%", "45%", "45%", "45%"]} mt="40px">
          <Box w="90%">
            <Heading
              bgGradient="linear-gradient(30.32deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
            >
              03.
            </Heading>
            <Heading size="2xl">
              Create a forum and showcase your project
            </Heading>
            <Text mt="20px" color="#7B7583">
              Create a forum and start your own community here.
              <br />
              <br />
            </Text>
            <Text color="#7B7583">
              Your profile is you, and you are the most important piece of the
              big puzzle economy. Contribute to earn more. Reward your community
              members. Showcase your projects and allow others to contribute to
              your success!
            </Text>
            <Button
              mt="20px"
              rightIcon={<GoArrowUpRight />}
              borderRadius="20px"
              variant="link"
            >
              Create your project
            </Button>
          </Box>
        </Box>
      </Flex>
      <Center my="50px">{Zige}</Center>
      <Box
        border="1px solid #83838340"
        borderRadius="20px"
        p={["20px", "20px", "40px", "40px", "40px", "40px"]}
        py="40px"
        m="auto"
        w={["90%", "90%", "40%", "40%", "40%", "40%"]}
        textAlign="center"
      >
        <Heading
          size={["md", "md", "2xl", "2xl", "2xl", "2xl"]}
          display="flex"
          justifyContent="center"
          color="secondaryGrade"
        >
          Never
          <Box
            bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
            bgClip="text"
          >
            &nbsp;miss a drop!
          </Box>
        </Heading>
        <Text color="#7B7583" mt="15px">
          Subscribe to our super-rare and exclusive drops.
        </Text>
        <Flex mt="30px">
          <Input type="email" variant="filled" borderRadius="50px" />
          <Button ml="10px" variant="primary2" borderRadius="50px">
            Subscribe
          </Button>
        </Flex>
      </Box>
      <Box p={"30px"} id="roadmap">
        <Center id="roadmap" my="60px">
          {Zige}
        </Center>
        <Heading
          size="2xl"
          display="flex"
          justifyContent="center"
          color="secondaryGrade"
        >
          Kryptonig's
          <Box
            bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
            bgClip="text"
          >
            &nbsp;Roadmap
          </Box>
        </Heading>
      </Box>
      <Flex justifyContent="space-around" flexWrap="wrap" mt="70px">
        {roadmap?.map((item) => (
          <Box
            p="15px"
            w={["90%", "90%", "45%", "20%", "20%", "20%"]}
            border="1px solid #83838340"
            borderRadius="20px"
            key={item?.id}
            m="20px"
          >
            <Box
              bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
              fontWeight="900"
            >
              PHASE {item?.id}
            </Box>
            <Heading size="md">{item?.name}</Heading>
            <Text color="#7B7583" mt="15px">
              {item?.text}
            </Text>
            <Spacer my="20px" border="1px solid #83838340" w="30%" />

            {item?.steps?.map((items) => (
              <Text>{items}</Text>
            ))}
          </Box>
        ))}
      </Flex>
      {/* <Flex justifyContent="space-around" flexWrap="wrap" mt="70px">
        {roadmap?.map((item) => (
          <Box
            p="15px"
            w={["45%", "45%", "45%", "20%", "20%", "20%"]}
            textAlign="center"
            borderRadius="20px"
            key={item?.id}
          >
            <Center>
              <Image
                h="150px"
                src="https://img.freepik.com/free-photo/androgynous-avatar-non-binary-queer-person_23-2151100205.jpg"
                border="1px solid #83838340"
                p="10px"
                borderRadius="10px"
              />
            </Center>
            <Heading size="sm">Steps Jods</Heading>
            <Text color="#7B7583" mt="5px">
              Artist
            </Text>
            <Center mt="-10px">
              <Box m="10px">
                <FaTwitter size="25px" />
              </Box>
              <Box m="10px">
                <FaDiscord size="25px" />
              </Box>
              <Box m="10px">
                <FaInstagram size="25px" />
              </Box>
            </Center>
          </Box>
        ))}
      </Flex> */}
      <Center my="60px">{Zige}</Center>
      <Heading size="2xl" color="secondaryGrade">
        <Center>Your questions,</Center>
        <Center>
          <Box
            bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
            bgClip="text"
          >
            answered
          </Box>
        </Center>
      </Heading>
      <Box
        border="1px solid #83838340"
        borderRadius="10px"
        m="auto"
        w={["90%", "90%", "90%", "40%", "40%", "40%"]}
        p="10px"
        px="20px"
        mt="60px"
        mb="50px"
      >
        <Faq data={faqdata} config={config} />
      </Box>
      <Center my="60px">{Zige}</Center>
      <Box>
        <Heading size="3xl" color="secondaryGrade">
          <Center>Kryptonig’s</Center>
          <Center>
            <Box
              mt="10px"
              bgGradient="linear-gradient(67.31deg, #2600FC 0%, #FF00EA 100%)"
              bgClip="text"
            >
              DAO LP?
            </Box>
          </Center>
        </Heading>
        <Text
          m="auto"
          w={["90%", "90%", "90%", "28%", "28%", "28%"]}
          textAlign="center"
          my="30px"
          color="#7B7583"
        >
          We are a project, just as yours. Fund and contribute to our community
          now to earn rewards.
        </Text>
        <Center>
          <Button
            rightIcon={<GoArrowUpRight />}
            borderRadius="20px"
            variant="primary2"
            onClick={handleCreate}
          >
            Get Started
          </Button>
        </Center>
      </Box>
      <Footer />
    </>
  );
};
//color="#D7D7D7"
export default Site;
