import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FaArrowRightLong } from "react-icons/fa6";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlOptions } from "react-icons/sl";
import { Box2 } from "../components/boxes";
export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const ProjectView = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const datas = {
      dob: dob,
      firstname: firstname,
      gender: gender,
      country: country,
      lastname: lastname,
    };
    localStorage.setItem("kycData1", JSON.stringify(datas));
    navigate("/kyc2");
  };
  return (
    <>
      <Flex
        bg="linear-gradient(95.19deg, rgba(79, 84, 227, 0.05) 29.12%, rgba(0, 119, 126, 0.0165) 111.32%, rgba(28, 217, 126, 0.0165) 111.32%);"
        p="20px"
        justifyContent="space-between"
        borderRadius="20px"
      >
        <Text fontSize="12px">
          <Heading mb="15px" size="sm">
            Add a new project
          </Heading>
          The drive for innovation can be integrated here
        </Text>
        <Button variant="primary">Add your project</Button>
      </Flex>
      <Flex flexWrap="wrap">
        {[1, 2, 3, 4, 6].map(() => (
          <Box2
            title="Project name lorem ipsum"
            vote="200/200 Vote"
            text="      So what are these strange lights in the sky? Are they aliens
            invading from Mars? Are the comets coming to start the next ice age?"
          />
        ))}
      </Flex>
    </>
  );
};
//color="#D7D7D7"
export default ProjectView;
