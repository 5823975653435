import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Stat,
  StatArrow,
  StatHelpText,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { LuGift } from "react-icons/lu";
import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuBadgeInfo } from "react-icons/lu";
import { IoIosStar } from "react-icons/io";
// @ts-expect-error
import MiniChart from "react-mini-chart";

export interface FormInputProps {
  children?: ReactNode;
  index?: number;
}
export const Profile = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [country, setCountry] = useState("");
  const submitHandler = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const datas = {
      dob: dob,
      firstname: firstname,
      gender: gender,
      country: country,
      lastname: lastname,
    };
    localStorage.setItem("kycData1", JSON.stringify(datas));
    navigate("/kyc2");
  };
  const reward = [
    { name: "Free creator credit", icon: <LuGift size="30px" /> },
    { name: "Easy access to new projects", icon: <LuBadgeInfo size="30px" /> },
    { name: "VIP collections", icon: <IoIosStar size="30px" /> },
  ];
  return (
    <>
      <Flex p="20px" justifyContent="space-between" borderRadius="20px">
        <Box w="62%" fontSize="13px" color="#7F838F">
          <Flex mt="20px">
            <Avatar
              size="2xl"
              name="Ryan Florence"
              src="https://bit.ly/ryan-florence"
            />
            <Flex w="90%" justifyContent="space-between">
              <Text w="90%" ml="15px">
                <Heading size="md" mb="10px">
                  John Doe
                </Heading>
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore
                </Text>
                <Flex mt="15px" justifyContent="space-between">
                  <Text>324 Contributions</Text>
                  <Text>324 Projects</Text>
                  <Text>56 Forums</Text>
                  <Text>500 Followers</Text>
                  <Text>500 Following</Text>
                </Flex>
              </Text>

              <Button h="35px" px="30px" variant="primary2">
                Edit profile
              </Button>
            </Flex>
          </Flex>
          <Tabs colorScheme="orange" mt="30px">
            <TabList>
              <Tab>Projects (324)</Tab>
              <Tab>Forums (56)</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {[5, 5, 34, 3, 4].map(() => (
                  <Box
                    display="flex"
                    my="20px"
                    p="20px"
                    bg="#FDF6F4"
                    borderRadius="10px"
                  >
                    <Box bg="white" p="15px" borderRadius="10px" w="170px">
                      <Flex>
                        <Avatar
                          size="sm"
                          name="Ryan Florence"
                          src="https://cdn4.vectorstock.com/i/1000x1000/77/63/bitcoin-logo-black-and-white-vector-20987763.jpg"
                        />
                        <Text ml="10px" mt="-3px">
                          <b>BTC</b>
                          <br />
                          Bitcoin
                        </Text>
                      </Flex>
                      <MiniChart
                        strokeWidth={2}
                        width={140}
                        dataSet={[0, -20, 343, 49.3, -100, -20, 343, 200, 78]}
                      />
                      <Flex justifyContent="space-between">
                        <Text fontWeight="800">$20,000</Text>
                        <Text color="green.300">+0.30%</Text>
                      </Flex>
                    </Box>
                    <Box px="20px" w="90%">
                      <Heading color="#7F838F" size="sm" mb="10px">
                        Lonelypop NFT
                      </Heading>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Flex justifyContent="space-between" mt="30px">
                        <Flex>
                          <Avatar size="sm" />
                          <Text ml="10px">
                            John Doe
                            <Text fontSize="10px" color="#A9ABBD">
                              2 hrs ago
                            </Text>
                          </Text>
                        </Flex>
                        <Text>500 Followers</Text>
                        <Text>500 Following</Text>
                      </Flex>
                    </Box>
                  </Box>
                ))}
              </TabPanel>
              <TabPanel>
                {[5, 5, 34, 3, 4].map(() => (
                  <Box
                    display="flex"
                    my="20px"
                    p="20px"
                    bg="#FDF6F4"
                    borderRadius="10px"
                  >
                    <Box bg="white" p="15px" borderRadius="10px" w="170px">
                      <Flex>
                        <Avatar
                          size="sm"
                          name="Ryan Florence"
                          src="https://cdn4.vectorstock.com/i/1000x1000/77/63/bitcoin-logo-black-and-white-vector-20987763.jpg"
                        />
                        <Text ml="10px" mt="-3px">
                          <b>BTC</b>
                          <br />
                          Bitcoin
                        </Text>
                      </Flex>
                      <MiniChart
                        strokeWidth={2}
                        width={140}
                        dataSet={[0, -20, 343, 49.3, -100, -20, 343, 200, 78]}
                      />
                      <Flex justifyContent="space-between">
                        <Text fontWeight="800">$20,000</Text>
                        <Text color="green.300">+0.30%</Text>
                      </Flex>
                    </Box>
                    <Box px="20px" w="90%">
                      <Heading color="#7F838F" size="sm" mb="10px">
                        Lonelypop NFT
                      </Heading>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Tag
                        borderRadius="full"
                        bg="#2C353D"
                        color="#C5D0E6"
                        size="md"
                        fontSize="12px"
                        mr="10px"
                      >
                        finance
                      </Tag>
                      <Flex justifyContent="space-between" mt="30px">
                        <Flex>
                          <Avatar size="sm" />
                          <Text ml="10px">
                            John Doe
                            <Text fontSize="10px" color="#A9ABBD">
                              2 hrs ago
                            </Text>
                          </Text>
                        </Flex>
                        <Text>500 Followers</Text>
                        <Text>500 Following</Text>
                      </Flex>
                    </Box>
                  </Box>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Box w="35%">
          <Box bg="#FDF6F4" borderRadius="30px" p="20px">
            <Heading size="sm" color="#7F838F">
              Your recent rewards
            </Heading>
            {[1, 2, 3, 4, 6].map(() => (
              <Flex borderRadius="10px" p="10px" bg="#FAEFED" mt="10px">
                <Image
                  h="40px"
                  src="https://miro.medium.com/v2/resize:fit:1200/1*FJHpzD8bt0qyx6nDXceZtA.jpeg"
                />
                <Text mt="10px" fontWeight="800" ml="15px" fontSize="13px">
                  Kryptonig meme token
                </Text>
                <Tag
                  ml="10px"
                  h="30px"
                  size="sm"
                  color="white"
                  mt="6px"
                  bg="#FF6934"
                >
                  24 points
                </Tag>
              </Flex>
            ))}
          </Box>
          <Box
            bg="#FDF6F4"
            color="#7F838F"
            borderRadius="30px"
            p="20px"
            mt="50px"
          >
            <Heading size="sm">
              Perform an activity to earn a reward now
            </Heading>
            {reward.map((item) => (
              <Flex justifyContent="space-between" mt="20px">
                <Flex>
                  <Avatar
                    border="1px solid black"
                    bg="white"
                    color="black"
                    icon={item?.icon}
                  />
                  <Text mt="13px" ml="15px">
                    {item?.name}
                  </Text>
                </Flex>
              </Flex>
            ))}
            <Button mt="30px" w="100%" px="30px" variant="primary">
              See all rewards
            </Button>
          </Box>
        </Box>
      </Flex>
    </>
  );
};
//color="#D7D7D7"
export default Profile;
